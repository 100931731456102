import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  reload: false,
  activities: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
  operations: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
  errors: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
  states: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
};

export const logSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setActivityLogs: (state, action) => {
      state.activities = action.payload;
    },
    resetActivityLogs: (state) => {
      state.activities = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    setOperationLogs: (state, action) => {
      state.operations = action.payload;
    },
    resetOperationLogs: (state) => {
      state.operations = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    setErrorLogs: (state, action) => {
      state.errors = action.payload;
    },
    resetErrorLogs: (state) => {
      state.errors = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    setStateLogs: (state, action) => {
      state.states = action.payload;
    },
    resetStateLogs: (state) => {
      state.states = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    startReload: (state) => {
      state.reload = true;
    },
    stopReload: (state) => {
      state.reload = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  setActivityLogs,
  resetActivityLogs,
  setOperationLogs,
  resetOperationLogs,
  setErrorLogs,
  resetErrorLogs,
  setStateLogs,
  resetStateLogs,
  startLoading,
  stopLoading,
  startReload,
  stopReload,
} = logSlice.actions;
export default logSlice.reducer;
