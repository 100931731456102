import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Button } from "misc/form";
import i18n from "i18n";
import { US, TR, RU } from "country-flag-icons/react/3x2";
const LanguageSwitcher = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const activeLangFlag = () => {
    switch (i18n.language) {
      case "en":
        return <US title="English" className="w-6 h-6" />;
      case "tr":
        return <TR title="Türkçe" className="w-6 h-6" />;
      case "ru":
        return <RU title="Русский" className="w-6 h-6" />;
      default:
        return <US title="English" className="w-6 h-6" />;
    }
  };

  return (
    <Menu as="nav" className={"relative"}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`flex items-center px-2 py-2 rounded-md  bg-opacity-70 text-gray-300 ${
              open ? "bg-active" : "bg-sidebar"
            }`}
          >
            {activeLangFlag()}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={
                "absolute p-1 top-full right-0 w-48 bg-active text-gray-300 rounded translate-y-2"
              }
            >
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 rounded`}
                    onClick={() => changeLanguage("en")}
                  >
                    <US title="English" className="w-10 h-10 mr-2" /> English
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 rounded`}
                    onClick={() => changeLanguage("tr")}
                  >
                    <TR title="Türkçe" className="w-10 h-10 mr-2" /> Türkçe
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 rounded`}
                    onClick={() => changeLanguage("ru")}
                  >
                    <RU title="Русский" className="w-10 h-10 mr-2" /> Русский
                  </Button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LanguageSwitcher;
