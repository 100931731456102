import logo from "assets/duetron_icon.svg";
import { NavLink } from "react-router-dom";
import {userRoutes} from "routes";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
export const Sidebar = () => {
    const {t} = useTranslation();

    const auth = useSelector((state) => state.auth);
    const show = (roles) => {
        return roles.includes(auth.role);
    }
    const menuItems = userRoutes.filter(f => f.showMenu === true);
    return (
        <aside className="w-64 flex flex-shrink-0 flex-col bg-sidebar">
            <div className="flex flex-col items-center ml-4 mt-6 mb-2 text-sm font-semibold text-gray-900 dark:text-white border-b border-footer pb-4">
                <img className="w-24 h-24 mb-4" src={logo} alt="logo" />
                Gateway Configurator
            </div>
            <div className="flex-auto overflow-auto scrollbar-thin scrollbar-rounded scrollbar-thumb-rounded-md scrollbar-thumb-active scrollbar-track-black">
                <nav className="flex-1 pb-4 border-b border-footer">
                    <span className="font-bold text-xs px-4 mb-2 uppercase text-gray-200">
                    {t('global.sidebarTitle')}
                    </span>
                    {menuItems.map((item, index) => (show(item.roles) && <NavLink
                                to={item.path}
                                key={`menu_item_${index}`}
                                className={({isActive}) => classNames({
                                    'duetron-sidebar-menu-default group': true,
                                    'duetron-sidebar-menu-link-active': isActive,
                                    'duetron-sidebar-menu-link': !isActive
                                })}
                            >
                                <div className="flex items-center justify-start space-x-1.5">
                                    <item.icon className="w-6 h-6 mr-4" />
                                
                                    <span className="group-hover:text-gray-200">{t(item.title)}</span>
                                </div>
                            </NavLink>))}
                </nav>
            </div>
        </aside>
    )
}

export default Sidebar;