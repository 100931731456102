import { useSettings } from "misc/settings";
import { useSelector } from "react-redux";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import SystemDevicesList from "./SystemDevicesList";
import { HUB_BASE_URL } from "configs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useComponentDidMount } from "misc/componentManager";
import { Button } from "misc/form";
import ProgressBar from "components/Misc/ProgressBar";
import SelectedDeviceList from "./SelectedDeviceList";
import { selectSystemDevice, unselectSystemDevice } from "store/DeviceSlice";
import store from "store";
import { TbChecks, TbTrash } from "react-icons/tb";
import DeviceService from "services/DeviceService";
import { startLoading, stopLoading } from "store/DeviceSlice";
import { useNotification } from "misc/notification";

const DeviceTransferForm = () => {
  const { t } = useTranslation();
  const { data } = useSettings();
  const { success, error } = useNotification();
  const { selectedDevices, systemDevices } = useSelector(
    (state) => state.devices
  );
  const [transfered, setTransfered] = useState(0);
  const [connection, setConnection] = useState();
  const [completeKey, setCompleteKey] = useState("");

  const handleSelectAllDevices = () =>
    systemDevices.data.map((device) =>
      store.dispatch(selectSystemDevice(device))
    );

  const handleUnselectAllDevices = () =>
    selectedDevices.map((device) =>
      store.dispatch(unselectSystemDevice(device.id))
    );
  const clearTransfer = () => {
    handleUnselectAllDevices();
    setTransfered(0);
  };
  const startTransfer = async () => {
    setTransfered(0);
    store.dispatch(startLoading());
    await DeviceService.transferAccessDevice(selectedDevices)
      .then((result) => {
        success(t("devices.messages.transferCompleted"));
        clearTransfer();
      })
      .catch((err) => {
        error(t("devices.messages.transferNotCompleted"));
      })
      .finally(() => {
        setCompleteKey();
        store.dispatch(stopLoading());
      });
  };

  useComponentDidMount(() => {
    createHubConnection();
  });

  const MessageTypes = {
    Success: "ReceiveDeviceTransferSuccessResult",
    Error: "ReceiveDeviceTransferErrorMessage",
    Progress: "ReceiveTransferProgress",
  };

  const createHubConnection = async () => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${HUB_BASE_URL}/device`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    connect.on(MessageTypes.Error, (message) => {
      error(message);
    });

    connect.on(MessageTypes.Success, (result) => {
      success(
        t("devices.messages.transferSuccess", {
          deviceName: result.deviceName,
        })
      );
    });

    connect.on(MessageTypes.Progress, (result) => {
      setTransfered(result);
    });

    await connect
      .start()
      .then(() => {
        setConnection(connect);
      })
      .catch((e) => {
        console.log("connection error", e);
      });
  };

  return (
    <div className="grid grid-cols-3 gap-x-2">
      <div className="col-span-1 pb-2">
        <div className="bg-yellow-600 text-xs font-bold text-black py-4 px-6 rounded">
          {t("devices.messages.selectedSystem", {
            accessSystem: data.accessSystem === 1 ? "BIS" : "AMS",
          })}
        </div>
      </div>
      <div className="col-span-2 pb-2">
        <div className="flex items-center bg-red-600 text-xs font-bold text-white py-2 px-4 rounded">
          <div className="flex-none">
            <span className="bg-white rounded-md text-red-600 px-4 py-2 mr-2">
              {selectedDevices.length}
            </span>{" "}
            {t("devices.messages.selectedCount")}
          </div>
          <div className="grow px-4">
            <ProgressBar
              barType="percent"
              bgColor="bg-red-800"
              partial={transfered}
              total={selectedDevices.length}
              completedText={t("devices.messages.transferCompleted")}
            />
          </div>
          <div className="flex-none">
            <Button
              type="button"
              disabled={selectedDevices.length === 0}
              onClick={startTransfer}
            >
              {t("devices.buttons.startTransfer")}
            </Button>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex justify-between w-full mb-2">
          <Button type="button" onClick={handleSelectAllDevices}>
            <TbChecks size={18} className="text-blue-600 mr-2" />{" "}
            {t("devices.tables.systemDevices.selectAll")}
          </Button>
          <Button
            type="button"
            onClick={handleUnselectAllDevices}
            disabled={selectedDevices.length === 0}
          >
            <TbTrash size={18} className="text-red-600 mr-2" />{" "}
            {t("devices.tables.systemDevices.clearSelected")}
          </Button>
        </div>
        <SystemDevicesList handleReload={completeKey} />
      </div>
      <div className="col-span-2">
        {0 < selectedDevices.length && <SelectedDeviceList />}
      </div>
    </div>
  );
};

export default DeviceTransferForm;
