import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  reload: false,
  accessStates: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
  selectedState: null,
  selectedStates: [],
  selectedIds: [],
};

export const stateSlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    setAccessStates: (state, action) => {
      state.accessStates = action.payload;
    },
    resetAccessStates: (state) => {
      state.accessStates = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    addAccessState: (state, action) => {
      state.accessStates = [...state.accessStates.data, action.payload];
    },
    updateAccessState: (state, action) => {
      state.accessStates.data = [
        ...state.accessStates.data.map((state) =>
          state.id === action.payload.id ? action.payload : state
        ),
      ];
    },
    selectAccessState: (state, action) => {
      state.selectedAccessState = action.payload;
    },
    unselectAccessState: (state) => {
      state.selectedAccessState = null;
    },
    deleteAccessState: (state, action) => {
      state.accessStates.data = [
        ...state.accessStates.data.filter((x) => x.id !== action.payload),
      ];
    },
    startReload: (state) => {
      state.reload = true;
    },
    stopReload: (state) => {
      state.reload = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    selectId: (state, action) => {
      const i = state.selectedIds.findIndex((e) => e === action.payload);
      if (i < 0) {
        state.selectedIds = [...state.selectedIds, action.payload];
      }
    },
    unselectId: (state, action) => {
      state.selectedIds = [
        ...state.selectedIds.filter((x) => x !== action.payload),
      ];
    },
  },
});

export const {
  addAccessState,
  updateAccessState,
  deleteAccessState,
  setAccessStates,
  selectAccessState,
  unselectAccessState,
  startLoading,
  stopLoading,
  resetAccessStates,
  selectId,
  unselectId,
  startReload,
  stopReload,
} = stateSlice.actions;
export default stateSlice.reducer;
