import { useNotification } from "misc/notification";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import store from "store";
import {
  startLoading,
  stopLoading,
  startSending,
  stopSending,
  setServices,
  resetServices,
} from "store/ServiceSlice";
import SettingsService from "services/SettingsService";

export const useServices = () => {
  const { data, isLoading, isSending } = useSelector((state) => state.services);
  const { success } = useNotification();
  const { t } = useTranslation();

  const resetServiceList = () => {
    store.dispatch(resetServices());
  };

  const getServices = async () => {
    store.dispatch(startLoading());
    await SettingsService.getServices()
      .then((res) => {
        const resData = res.data.data;
        store.dispatch(setServices(resData));
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };

  const start = async (id) => {
    await sendCommand(id, 1);
  };
  const stop = async (id) => await sendCommand(id, 2);
  const pause = async (id) => await sendCommand(id, 3);
  const restart = async (id) => await sendCommand(id, 4);

  const sendCommand = async (id, command) => {
    store.dispatch(startSending());
    await SettingsService.updateCommand({ serviceId: id, command: command })
      .then((res) => {
        success(t("services.messages.sended"));
        getServices();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopSending());
      });
  };

  return {
    data,
    isLoading,
    isSending,
    getServices,
    resetServiceList,
    start,
    stop,
    pause,
    restart,
  };
};
