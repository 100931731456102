import Notiflix from 'notiflix';
import i18n from "i18n";

export const useConfirm = () => {
    const confirm = (question, accept, reject) => {
        Notiflix.Confirm.init({
            backgroundColor: '#222222',
            messageColor: '#b3b3b3'
        })
        Notiflix.Confirm.show(
            i18n.t('confirmations.warning.title'),
            question,
            i18n.t('confirmations.warning.yes'),
            i18n.t('confirmations.warning.no'),
            () => {
                accept();
            },
            () => {
                reject()
            }
        )
    }

    return {
        confirm
    }
}