import { CgSpinnerTwo } from "react-icons/cg";
import { forwardRef } from "react";
import classNames from "classnames";

const Button = (
  { children, loading = false, color = null, size = null, ...props },
  ref
) => {
  return (
    <button
      disabled={loading}
      className={classNames({
        "flex relative items-center justify-center text-center text-xs hover:bg-opacity-50 disabled:opacity-50 disabled:text-opacity-50 disabled:pointer-events-none group/button": true,
        "bg-primary text-black": color === "success",
        "bg-red-800 text-red-200": color === "error",
        "bg-blue-800 text-blue-200": color === "info",
        "bg-orange-800 text-orange-200": color === "warning",
        "bg-gray-600 text-gray-200": color === "gray",
        "bg-green-800 text-green-200": color === "green",
        "text-link": color === "link",
        "text-black": color === "dark-link",
        "bg-zinc-800 text-zinc-200": color === "default" || color === null,
        "bg-backdrop text-white": color === "dark",
        "h-6 px-4 rounded": size === "xs",
        "p-2 rounded-full": size === "icon",
        "py-2 px-4 rounded": size === "default" || size === null,
      })}
      {...props}
    >
      {loading && (
        <CgSpinnerTwo className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" />
      )}{" "}
      {children}
    </button>
  );
};
export default forwardRef(Button);
