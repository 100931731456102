import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSending: false,
  data: [],
};

export const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.data = action.payload;
    },
    resetServices: (state) => {
      state.data = [];
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startSending: (state) => {
      state.isSending = true;
    },
    stopSending: (state) => {
      state.isSending = false;
    },
  },
});
export const {
  setServices,
  resetServices,
  startLoading,
  stopLoading,
  startSending,
  stopSending,
} = serviceSlice.actions;
export default serviceSlice.reducer;
