import * as Yup from "yup";
import store from "store";
import { startLoading, stopLoading, startReload } from "store/StateSlice";
import StateService from "services/StateService";
import { Button, DarkInput } from "misc/form";
import { useNotification } from "misc/notification";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { FiSave } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useSelector } from "react-redux";

const StateEditForm = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "states.forms.addState",
  });
  const { success } = useNotification();
  const navigate = useNavigate();
  const formikRef = useRef();
  const { selectedAccessState } = useSelector((state) => state.states);
  const initialValues = () => ({
    id: selectedAccessState.id ?? null,
    value: selectedAccessState.value ?? null,
    description: selectedAccessState.description ?? null,
    info: selectedAccessState.info ?? null,
  });
  const StateUpdateValidation = Yup.object().shape({
    id: Yup.number().required(),
    value: Yup.string().required(t("value.validations.required")),
    description: Yup.string().nullable(),
    info: Yup.string().nullable(),
  });
  return (
    <div className="col-span-1 p-4 bg-sidebar rounded-md  transition-all">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues()}
        validationSchema={StateUpdateValidation}
        onSubmit={async (values, actions) => {
          store.dispatch(startLoading());
          await StateService.updateForAccess(values)
            .then((res) => {
              const data = res.data.data;
              store.dispatch(startReload());
              success(`${data.value} başarıyla güncellendi`);
              navigate("/states");
            })
            .catch((error) => {
              error.handleGlobally && error.handleGlobally();
            })
            .finally(() => {
              store.dispatch(stopLoading());
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, values }) => (
          <Form className="py-4 px-2">
            <DarkInput
              name="value"
              label={t("value.label")}
              placeholder={t("value.placeHolder")}
            />
            <DarkInput
              name="description"
              label={t("description.label")}
              placeholder={t("description.placeHolder")}
            />
            <DarkInput
              name="info"
              label={t("info.label")}
              placeholder={t("info.placeHolder")}
            />
            <div className="flex justify-end items-right text-lg pb-2 border-b-sidebar text-link font-medium col-span-3 gap-x-2">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                color="green"
              >
                {!isSubmitting && <FiSave className="mr-2 w-5 h-5" />}{" "}
                {t("update", {
                  keyPrefix: "global.buttons",
                })}
              </Button>
              <Button
                type="button"
                color="error"
                onClick={() => navigate("/states")}
              >
                <MdOutlineCancel className="mr-2 w-5 h-5 transition-all" />{" "}
                {t("cancel", {
                  keyPrefix: "global.buttons",
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StateEditForm;
