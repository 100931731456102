import * as Yup from 'yup';
import i18n from "i18n";

export const UserCreateValidation = Yup.object().shape({
    username: Yup
                .string()
                .required(i18n.t('user.addUser.form.username.validations.required')),
    password: Yup
                .string()
                .required(i18n.t('user.addUser.form.password.validations.required')),
    confirmPassword: Yup
                .string()
                .required(i18n.t('user.addUser.form.confirmPassword.validations.required')),
    info: Yup
        .string()
        .nullable(),
    isAdmin: Yup.bool().nullable()
});

export default UserCreateValidation;