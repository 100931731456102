import { useTranslation } from "react-i18next";
import Table from "misc/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setActivityLogs,
  resetActivityLogs,
  startLoading,
  stopLoading,
} from "store/LogSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import store from "store";
import LogService from "services/LogService";

const ActivityLogs = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "logs.tables.activities",
  });
  const { activities, isLoading } = useSelector((state) => state.logs);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    setPageNumber(activities.pageNumber);
    setPageSize(activities.pageSize);
    setSortColumn(activities.order.column);
    setSortDirection(activities.order.sort);
  }, [activities]);

  useEffect(() => {
    const loadData = async () => {
      store.dispatch(startLoading());
      await LogService.getActivities({
        params: {
          pageNumber,
          pageSize,
          column: sortColumn,
          sort: sortDirection,
        },
      })
        .then((res) => {
          const result = res.data;
          store.dispatch(setActivityLogs(result));
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
    };
    loadData();
  }, [pageNumber, pageSize, sortColumn, sortDirection]);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleSorting = (sortType) => {
    if (sortType === null) {
      setSortColumn("id");
      setSortDirection("desc");
    } else {
      setSortColumn(sortType.column);
      setSortDirection(sortType.orderBy);
    }
  };

  useComponentDidMount(() => {
    handlePageChange(1);
  });

  useComponentWillUnmount(() => {
    store.dispatch(resetActivityLogs());
  });

  const heads = [
    {
      title: "ID",
      style: "w-8",
      column: "id",
    },
    {
      title: t("type"),
      style: "w-8",
      column: "actionType",
    },
    {
      title: t("details"),
      style: "",
      sortable: true,
      column: "actionDetails",
    },
    {
      title: t("ipAddress"),
      style: "",
      sortable: true,
      column: "actionIpAddress",
    },
    {
      title: t("time"),
      style: "",
      sortable: true,
      column: "actionTime",
    },
    {
      title: t("user"),
      style: "",
      sortable: false,
      column: "userId",
    },
  ];

  return (
    <Table
      isLoading={isLoading}
      serverSide={true}
      head={heads}
      searchable={false}
      searchPlaceHolder={"Loglarda ara"}
      body={activities.data.map((activity, index) => [
        (activities.pageNumber - 1) * activities.pageSize + 1 + index,
        activity.actionType,
        activity.actionDetails,
        activity.actionIpAddress === "::1"
          ? "localhost"
          : activity.actionIpAddress,
        activity.actionTime,
        activity.userId ?? "Guest Action",
      ])}
      pagination={{
        firstPage: activities.firstPageNumber,
        lastPage: activities.lastPageNumber,
        nextPage: activities.nextPageNumber,
        pageNumber: activities.pageNumber,
        pageSize: activities.pageSize,
        previousPage: activities.previousPageNumber,
        totalPages: activities.totalPages,
        totalRecords: activities.totalRecords,
      }}
      changePageHandler={(uri) => handlePageChange(uri)}
      changeSortingHandler={(sortType) => handleSorting(sortType)}
    />
  );
};

export default ActivityLogs;
