import ApiService from "./ApiService";
import { STORAGE_PREFIX } from "configs";

class SettingsService {
  TokenPrefix = STORAGE_PREFIX + "_token";

  get = async () => {
    return await ApiService.get("settings", {});
  };

  update = async ({
    id,
    accessServer,
    accessUsername,
    accessPassword,
    accessSystem,
    integrationType,
    opcServer,
    keepLogDay,
  }) => {
    return await ApiService.put("settings", {
      id,
      accessServer,
      accessUsername,
      accessPassword,
      accessSystem,
      integrationType,
      opcServer,
      keepLogDay,
    });
  };

  getServices = async () => await ApiService.get("services", {});
  updateCommand = async (command) => await ApiService.put("services", command);
}
export const service = new SettingsService();
export default service;
