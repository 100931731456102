import { useTranslation } from "react-i18next";
import Table from "misc/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setOperationLogs,
  resetOperationLogs,
  startLoading,
  stopLoading,
} from "store/LogSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import store from "store";
import LogService from "services/LogService";

const OperationLogs = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "logs.tables.operations",
  });
  const { operations, isLoading } = useSelector((state) => state.logs);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    setPageNumber(operations.pageNumber);
    setPageSize(operations.pageSize);
    setSortColumn(operations.order.column);
    setSortDirection(operations.order.sort);
  }, [operations]);

  useEffect(() => {
    const loadData = async () => {
      store.dispatch(startLoading());
      await LogService.getOperations({
        params: {
          pageNumber,
          pageSize,
          column: sortColumn,
          sort: sortDirection,
        },
      })
        .then((res) => {
          const result = res.data;
          store.dispatch(setOperationLogs(result));
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
    };
    loadData();
  }, [pageNumber, pageSize, sortColumn, sortDirection]);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleSorting = (sortType) => {
    if (sortType === null) {
      setSortColumn("id");
      setSortDirection("desc");
    } else {
      setSortColumn(sortType.column);
      setSortDirection(sortType.orderBy);
    }
  };

  useComponentDidMount(() => {
    handlePageChange(1);
  });

  useComponentWillUnmount(() => {
    store.dispatch(resetOperationLogs());
  });

  const heads = [
    {
      title: "#",
      style: "w-8",
      column: "id",
    },
    {
      title: t("module"),
      style: "w-8",
      column: "module",
    },
    {
      title: t("message"),
      style: "",
      sortable: true,
      column: "message",
    },
    {
      title: t("time"),
      style: "",
      sortable: true,
      column: "tmTime",
    },
  ];

  return (
    <Table
      isLoading={isLoading}
      serverSide={true}
      head={heads}
      searchable={false}
      searchPlaceHolder={"Loglarda ara"}
      body={operations.data.map((operation, index) => [
        (operations.pageNumber - 1) * operations.pageSize + 1 + index,
        operation.module,
        operation.message,
        operation.tmTime,
      ])}
      pagination={{
        firstPage: operations.firstPageNumber,
        lastPage: operations.lastPageNumber,
        nextPage: operations.nextPageNumber,
        pageNumber: operations.pageNumber,
        pageSize: operations.pageSize,
        previousPage: operations.previousPageNumber,
        totalPages: operations.totalPages,
        totalRecords: operations.totalRecords,
      }}
      changePageHandler={(uri) => handlePageChange(uri)}
      changeSortingHandler={(sortType) => handleSorting(sortType)}
    />
  );
};

export default OperationLogs;
