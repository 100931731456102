import { useSettings } from "misc/settings";
import Table from "misc/table";
import store from "store";
import { setSystemDevices, startLoading, stopLoading, resetSystemDevices, selectSystemDevice, unselectSystemDevice } from "store/DeviceSlice";
import DeviceService from "services/DeviceService";
import { useSelector } from "react-redux";
import { Button } from "misc/form";
import { useComponentDidMount, useComponentWillUnmount } from "misc/componentManager";
import { useEffect, useState } from "react";
import {TbCheck, TbTrash } from "react-icons/tb";
import { useTranslation } from "react-i18next";
const SystemDevicesList = ({handleReload}) => {
    const { t } = useTranslation();
    const { data } = useSettings();
    const { systemDevices, selectedDevices, isLoading } = useSelector((state) => state.devices);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ sortColumn, setSortColumn ] = useState('id');
    const [ sortDirection, setSortDirection ] = useState('asc');

    const handleSelectDevice = (device) => {
        store.dispatch(selectSystemDevice(device))
    }

    const removeSelectedDevice = (device) => {
        store.dispatch(unselectSystemDevice(device.id))
    }

    const hasSelected = (id) => {
        const i = selectedDevices.findIndex(e => e.id === id);

        return -1 < i ? true : false;
    }

    useEffect(() => {
        setPageNumber(systemDevices.pageNumber)
        setPageSize(systemDevices.pageSize)
        setSortColumn(systemDevices.order.column)
        setSortDirection(systemDevices.order.sort)
    }, [systemDevices])

    useEffect(() => {
        const loadData = async () => {
            store.dispatch(startLoading())
            if(data.accessSystem !== null) {
                await DeviceService.getListForSystem({
                    system: data.accessSystem === 1 ? 'bis' : 'ams',
                    params: {
                        pageNumber,
                        pageSize,
                        column:sortColumn,
                        sort: sortDirection
                    }
                    }).then((res) => {
                        const result = res.data;
                        store.dispatch(setSystemDevices(result))
                    })
                    .catch(error => {
                        error.handleGlobally && error.handleGlobally(); 
                    })
                    .finally(() => {
                        store.dispatch(stopLoading())
                    })
            }
        }
        loadData()
    }, [pageNumber, pageSize, sortColumn, sortDirection, data.accessSystem, handleReload])

    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber)
    }

    const handleSorting = (sortType) => {
        if(sortType === null) {
            setSortColumn('id');
            setSortDirection('asc');
        }
        else {
            setSortColumn(sortType.column);
            setSortDirection(sortType.orderBy);
        }
    }


    useComponentDidMount(() => {
        if(data.accessSystem !== null) {
            handlePageChange(1);
        }
    })
    useComponentWillUnmount(() => {
        store.dispatch(resetSystemDevices());
    }, [])
    const heads = [
        {
            title: "#",
            style: "w-8",
            column: "id"
        },
        {
            title: t('devices.tables.systemDevices.deviceName'),
            style: "",
            sortable: true,
            column: "displayText"
        },
        {
            title: t('devices.tables.systemDevices.deviceType'),
            style: "",
            sortable: true,
            column: "type"
        },
        {
            title: t('devices.tables.systemDevices.action'),
            style: "w-28 text-center items-center"
        }
    ]
    return <Table 
        isLoading={isLoading}
        serverSide={true}
        head={heads}
        searchable={false}
        searchPlaceHolder={'Cihazlarda ara'}
        body = {systemDevices.data.map((device, index) => [
            ((systemDevices.pageNumber-1)*systemDevices.pageSize)+1+index,
            device.displayText,
            device.type,
            (<div className="flex justify-center text-center">
                {
                    hasSelected(device.id) ? <Button 
                    type="button" 
                    color="error" 
                    size="icon" 
                    onClick={() => removeSelectedDevice(device)}
                ><TbTrash size={18} /></Button> : <Button 
                    type="button" 
                    color="info" 
                    size="icon" 
                    onClick={() => handleSelectDevice(device)}
                ><TbCheck size={18} /></Button>
                } 
            </div>)
        ])}
        pagination={{
            firstPage: systemDevices.firstPageNumber,
            lastPage: systemDevices.lastPageNumber,
            nextPage: systemDevices.nextPageNumber,
            pageNumber: systemDevices.pageNumber,
            pageSize: systemDevices.pageSize,
            previousPage: systemDevices.previousPageNumber,
            totalPages: systemDevices.totalPages,
            totalRecords: systemDevices.totalRecords
        }}
        changePageHandler={(uri) => handlePageChange(uri)}
        changeSortingHandler={(sortType) => handleSorting(sortType)}
    />
}

export default SystemDevicesList;