import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Modal from "misc/modals";
import { useModals } from "misc/modals/ModalHook";
import { userRoutes, guestRoutes } from "routes";
import Footer from "components/Footer";
import UserLayout from "layouts/UserLayout";
import GuestLayout from "layouts/GuestLayout";
import { ToastContainer } from 'react-toastify';

export const App = () => {
  const modals = useModals();
  return (
    <Router>
      {modals.length > 0 && <Modal />}
      <div className="app-container">
      <Routes>
          <Route exact element={<UserLayout />}>
            {userRoutes.map((route, index) => (
              <Route
                key={`user_route_${index}`}
                path={route.path}
                element={route.element}
              >
                {route.children && route.children.map((sub, subindex) => (
                  <Route key={`user_subroute_${subindex}`} path={sub.path} element={sub.element} />
                ))}
              </Route>
            ))}
          </Route>
          <Route exact element={<GuestLayout />}>
            {guestRoutes.map((route, index) => (
              <Route
                key={`guest_route_${index}`}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        </Routes>
      </div>
      <Footer />
      <ToastContainer />
    </Router>
  );
}

export default App;
