import classNames from "classnames";
import { useState, useEffect } from "react"

const ProgressBar = ({
    barType = 'percent',
    bgColor,
    barColor = 'bg-black/30',
    textColor = 'text-white',
    completedText,
    partial,
    total
}) => {

    const [getPartial, setPartial] = useState(0);
    const [getTotal, setTotal] = useState(0);

    useEffect(() => {
        setPartial(partial);
    }, [partial]);

    useEffect(() => {
        setTotal(total);
    }, [total])

    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    }

    const percentageText = (partialValue, totalValue) => {
        return (<p className="pt-1">{partial}/{total}</p>)
    }

    return (
        <div 
            className={classNames({
                [`${bgColor} rounded`]: true,
                'h-6': barType === 'percent',
                'h-4': barType === 'large',
                'h-1': barType === 'thin'
            })}
            role="progressbar" 
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <div 
                className={classNames({
                    [`${barColor} ${textColor} rounded transition`]: true,
                    'h-6 text-center': barType === 'percent',
                    'h-4': barType === 'large',
                    'h-1': barType === 'thin'
                })}
                style={{
                    width: percentage(getPartial, getTotal)+'%',
                    transition: 'width 2s'
                }}
                >
                    {barType === 'percent' && (((0 < getTotal && 0 < getPartial) && getPartial === getTotal) ? (<p>{completedText}</p>) : (
                        percentageText(getPartial, getTotal)
                    ))}
            </div>
        </div>
    )
}

export default ProgressBar;