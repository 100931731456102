import logo from "assets/duetron_icon.svg";

export const Footer = () => {
    return (
        <footer className="h-8 bg-sidebar border-t border-white border-opacity-5">
            <div className="flex items-center text-gray-300 m-1 text-sm">
                <img src={logo} className="h-6 w-6 mr-3" alt="duetron logo" /> &copy; Duetron Gateway Software
            </div>
        </footer>
    )
}

export default Footer;