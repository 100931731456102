import { Formik, Form } from "formik";
import { Input, Button } from "misc/form";
import AuthService from "services/AuthService";
import { useAuth } from "misc/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSettings } from "misc/settings";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const LoginForm = () => {
  const { t } = useTranslation();
  const { loginUser, isAuthenticated } = useAuth();
  const { getSettings } = useSettings();
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  const LoginValidation = Yup.object().shape({
    username: Yup.string().required(
      t("login.form.username.validations.required")
    ),
    password: Yup.string().required(
      t("login.form.password.validations.required")
    ),
  });
  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated, navigate]);
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={LoginValidation}
      onSubmit={async (values) => {
        await AuthService.login(values.username, values.password)
          .then((result) => {
            const data = result.data.data;
            AuthService.setToken(data.token);
            loginUser();
            getSettings();
            navigate("/");
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          });
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className="space-y-4 md:space-y-6">
          <Input
            name="username"
            label={t("login.form.username.label")}
            placeholder={t("login.form.username.placeHolder")}
          />
          <Input
            name="password"
            type="password"
            label={t("login.form.password.label")}
            placeholder={t("login.form.password.placeHolder")}
          />
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || !dirty}
            className="flex items-center justify-center w-full text-black bg-primary hover:bg-primary/80 disabled:bg-primary/60 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
          >
            {t("login.form.loginButton")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
