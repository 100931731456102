import {CgSpinnerTwo} from "react-icons/cg"
import { forwardRef, useState } from 'react';
import classNames from "classnames";
import {TbTrash} from "react-icons/tb";
import { useComponentDidMount } from "misc/componentManager";

const TagButton = ({children, loading = false, color = null, Icon = null, text = null, ...props}, ref) => {
    const [showIcon, setShowIcon] = useState();
    const buttonClass = {
        'text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full hover:bg-opacity-80': true,
        'bg-blue-200 text-blue-700': color === 'info',
        'bg-green-200 text-green-700': color === 'success',
        'bg-orange-200 text-orange-700': color === 'warning',
        'bg-red-200 text-red-700': color === 'danger',
        'bg-white text-gray-700': color === 'default' || color === null
    };

    const iconClass = {
        '"w-4 h-4 mr-2 -ml-1 "': true,
        'text-blue-700': color === 'info',
        'text-green-700': color === 'success',
        'text-orange-700': color === 'warning',
        'text-red-700': color === 'danger',
        'text-gray-700': color === 'default' || color === null
    };
    
    const buttonDefaultIcon = <Icon className={classNames(iconClass)}/>
    const buttonHoverIcon = <TbTrash className={classNames(iconClass)} />

    useComponentDidMount(() => {
        if(Icon !== null) {
            setShowIcon(buttonDefaultIcon)
        }
    })

	return (
		<button 
			disabled={loading} 
			className={classNames(buttonClass)} 
            onMouseEnter={() => setShowIcon(buttonHoverIcon)}
            onMouseLeave={() => setShowIcon(buttonDefaultIcon)}
            {...props}>
			{loading ? <CgSpinnerTwo className={classNames(...iconClass, {
                'animate-spin': true
            })} /> : 
             (Icon !== null && showIcon)} {text ?? children}
		</button>
	)
}
export default forwardRef(TagButton);