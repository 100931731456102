import ServiceTable from "./components/ServiceTable";
import StatsBoard from "./components/StatsBoard";

export const Dashboard = () => {
  return (
    <div>
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
        <StatsBoard />
      </div>
      <div>
        <ServiceTable />
      </div>
    </div>
  );
};

export default Dashboard;
