import ApiService from "./ApiService";

class DeviceService {
  transferAccessDevice = async (values) =>
    await ApiService.post("devices/transfer/access", values);
  assignMultipleAccessDevice = async (values) =>
    await ApiService.post("devices/attach/access/multiple", values);
  unassignAccessDevice = async (params) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.delete(`devices/detach/access?${queryParams}`, {});
  };
  //List Services
  getListForAccess = async ({ params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`devices/list/access?${queryParams}`, {});
  };
  addForAccess = async (values) =>
    await ApiService.post("devices/add/access", values);
  updateForAccess = async (values) =>
    await ApiService.put("devices/update/access", values);
  deleteForAccess = async (id) =>
    await ApiService.delete(`devices/delete/access?id=${id}`, {});
  getListForSystem = async ({ system, params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`devices/list/${system}?${queryParams}`, {});
  };
  getListForAms = async (uri) =>
    await ApiService.get(uri !== undefined ? uri : "devices/list/ams", {});
}

export const service = new DeviceService();
export default service;
