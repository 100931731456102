import Notiflix from 'notiflix';

export const useNotification = () => {
    const success = (message) => Notiflix.Notify.success(message, {
        position: 'right-top'
    });
    const error = (message) => Notiflix.Notify.failure(message, {
        position: 'right-top'
    });
    const info = (message) => Notiflix.Notify.info(message, {
        position: 'right-top'
    });
    const warning = (message) => Notiflix.Notify.warning(message, {
        position: 'right-top'
    });

    return {
        success,
        error,
        info,
        warning
    }
}