import { useTranslation } from "react-i18next";
import Table from "misc/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setErrorLogs,
  resetErrorLogs,
  startLoading,
  stopLoading,
} from "store/LogSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import store from "store";
import LogService from "services/LogService";

const ErrorLogs = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "logs.tables.errors",
  });
  const { errors, isLoading } = useSelector((state) => state.logs);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    setPageNumber(errors.pageNumber);
    setPageSize(errors.pageSize);
    setSortColumn(errors.order.column);
    setSortDirection(errors.order.sort);
  }, [errors]);

  useEffect(() => {
    const loadData = async () => {
      store.dispatch(startLoading());
      await LogService.getErrors({
        params: {
          pageNumber,
          pageSize,
          column: sortColumn,
          sort: sortDirection,
        },
      })
        .then((res) => {
          const result = res.data;
          store.dispatch(setErrorLogs(result));
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
    };
    loadData();
  }, [pageNumber, pageSize, sortColumn, sortDirection]);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleSorting = (sortType) => {
    if (sortType === null) {
      setSortColumn("id");
      setSortDirection("desc");
    } else {
      setSortColumn(sortType.column);
      setSortDirection(sortType.orderBy);
    }
  };

  useComponentDidMount(() => {
    handlePageChange(1);
  });

  useComponentWillUnmount(() => {
    store.dispatch(resetErrorLogs());
  });

  const heads = [
    {
      title: "#",
      style: "w-8",
      column: "id",
    },
    {
      title: t("module"),
      style: "w-8",
      column: "module",
    },
    {
      title: t("message"),
      style: "",
      sortable: true,
      column: "message",
    },
    {
      title: t("time"),
      style: "",
      sortable: true,
      column: "tmTime",
    },
  ];

  return (
    <Table
      isLoading={isLoading}
      serverSide={true}
      head={heads}
      searchable={false}
      searchPlaceHolder={"Loglarda ara"}
      body={errors.data.map((error, index) => [
        (errors.pageNumber - 1) * errors.pageSize + 1 + index,
        error.module,
        error.message,
        error.tmTime,
      ])}
      pagination={{
        firstPage: errors.firstPageNumber,
        lastPage: errors.lastPageNumber,
        nextPage: errors.nextPageNumber,
        pageNumber: errors.pageNumber,
        pageSize: errors.pageSize,
        previousPage: errors.previousPageNumber,
        totalPages: errors.totalPages,
        totalRecords: errors.totalRecords,
      }}
      changePageHandler={(uri) => handlePageChange(uri)}
      changeSortingHandler={(sortType) => handleSorting(sortType)}
    />
  );
};

export default ErrorLogs;
