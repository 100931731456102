import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button } from "misc/form";

const TablePagination = ({ pagination, changePageHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mt-2">
      <div className="flex-none">
        <Button
          type="button"
          onClick={() => changePageHandler(pagination.previousPage)}
          disabled={pagination.previousPage === null}
          className="inline-flex items-center px-4 py-2 text-xs font-medium text-link bg-backdrop rounded-l hover:bg-opacity-80 disabled:bg-opacity-80"
        >
          <MdOutlineKeyboardArrowLeft size={20} className="mr-2" />
          {t("misc.table.pagination.prev")}
        </Button>
      </div>
      <div className="flex-auto text-center bg-backdrop py-1.5 bg-opacity-80 border-x border-black">
        <span className="text-xs text-gray-700 dark:text-gray-400">
          {t("misc.table.pagination.dataSummary", {
            first:
              0 < pagination.totalRecords
                ? (pagination.pageNumber - 1) * pagination.pageSize + 1
                : 0,
            last:
              pagination.nextPage !== null
                ? (pagination.pageNumber - 1) * pagination.pageSize +
                  1 +
                  pagination.pageSize -
                  1
                : pagination.totalRecords,
            total: pagination.totalRecords,
          })}
        </span>
      </div>
      <div className="flex-none">
        <Button
          type="button"
          onClick={() => changePageHandler(pagination.nextPage)}
          disabled={pagination.nextPage === null}
          className="inline-flex items-center px-4 py-2 text-xs font-medium text-link bg-backdrop rounded-r hover:bg-opacity-80 disabled:bg-opacity-80"
        >
          {t("misc.table.pagination.next")}
          <MdOutlineKeyboardArrowRight size={20} className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default TablePagination;
