import { AiOutlineCloseCircle } from "react-icons/ai";
import { destroyModal } from "misc/modals/ModalHook";
const ModalHeader = (props) => {
  const { children } = props;
  return (
    <header className="flex items-center justify-between px-2 py-1 border-b border-footer">
      <h6 className="flex gap-x-2 text-link font-medium">{children}</h6>
      <button
        className="p-2 rounded-full hover:bg-sidebar flex items-center justify-center"
        onClick={destroyModal}
      >
        <AiOutlineCloseCircle
          size={24}
          className="text-link hover:text-white"
        />
      </button>
    </header>
  );
};

export default ModalHeader;
