import ModalHeader from "./ModalHeader";
import { FiKey } from "react-icons/fi";
import { DarkInput, Button } from "misc/form";
import { Formik, Form } from "formik";
import UserService from "services/UserService";
import { useTranslation } from "react-i18next";
import { UserPasswordValidation } from "validations/UserPasswordValidation";
import { useNotification } from "misc/notification";
const UserChangePassword = ({ data, close }) => {
  const { t } = useTranslation();
  const { success } = useNotification();
  return (
    <div className="w-[700px]">
      <ModalHeader>
        <FiKey size={24} className="text-primary" />{" "}
        {t("user.changePassword.title", { username: data.username })}
      </ModalHeader>
      <Formik
        initialValues={{
          id: data.id,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={UserPasswordValidation}
        onSubmit={async (values) => {
          await UserService.changePassword(
            data.id,
            values.password,
            values.confirmPassword
          )
            .then(() => {
              success(t("user.changePassword.form.messages.success"));
              close();
            })
            .catch((error) => {
              error.handleGlobally && error.handleGlobally();
            });
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="py-4 px-2">
            <DarkInput
              name="password"
              type="password"
              label={t("user.changePassword.form.password.label")}
              placeholder={t("user.changePassword.form.password.placeHolder")}
            />
            <DarkInput
              name="confirmPassword"
              type="password"
              label={t("user.changePassword.form.confirmPassword.label")}
              placeholder={t(
                "user.changePassword.form.confirmPassword.placeHolder"
              )}
            />
            <br />
            <div className="mr-2 mb-2 flex gap-x-2">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                className="flex items-center justify-center px-4 py-2 ml-auto bg-green-800 disabled:bg-green-800 border-green-800 disabled:border-green-800 hover:bg-green-600 border hover:border-green-600 disabled:opacity-50 rounded-md text-sm"
              >
                {t("global.buttons.update")}
              </Button>
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={close}
                className="px-4 py-2 bg-footer border-footer hover:bg-black border hover:border-black rounded-md text-sm"
              >
                {t("global.buttons.cancel")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserChangePassword;
