import ApiService from "./ApiService";
import StorageService from "./StorageService";
import * as AuthConstants from "constants/AuthConstants";
import {STORAGE_PREFIX} from "configs";
import jwt_decode from "jwt-decode";

class AuthService {

    TokenPrefix = STORAGE_PREFIX+'_token';

    login = async (username, password) => {
        return await ApiService.post('auth', {username, password})
    }

    logout = () => {
        StorageService.storage().delete(this.TokenPrefix);
    }

    changePassword = async (currentPassword, newPassword, confirmPassword) => {
        return await ApiService.put('auth', {currentPassword, newPassword, confirmPassword});
    }

    profile = () => {
        const profile = jwt_decode(this.token());

        return {
            id: profile[AuthConstants.AUTH_PROFILE_ID],
            username: profile[AuthConstants.AUTH_PROFILE_USERNAME],
            role: profile[AuthConstants.AUTH_PROFILE_ROLE],
            exp: profile[AuthConstants.AUTH_PROFILE_EXP],
            token: this.token()
        }
    }

    token = () => {
        return StorageService.storage().get(this.TokenPrefix);
    }

    hasToken = () => {
        return StorageService.storage().has(this.TokenPrefix);
    }

    setToken = (token) => {
        StorageService.storage().set(this.TokenPrefix, token);
    }
}
export const service = new AuthService();
export default service;