import { useField, Field } from "formik";
import classNames from "classnames";
import { useEffect } from "react";

export default function NumberInput({ label, ...props }) {
	const [fields, meta, helpers] = useField(props)
    const increment = value => {
        helpers.setValue(fields.value+1)
	}
    const descrement = value => {
        helpers.setValue(fields.value-1)
    }
    useEffect(() => {
        if(fields.value < 0) {
            helpers.setValue(0)
        }
    }, [fields.value, helpers])
	return (
		<div className="flex flex-auto">
            <label className="flex flex-col flex-auto mb-2">
                <div className="duetron-form-label">{label}</div>
                <Field className={classNames({
                    "duetron-modal-form-input": true,
                    "duetron-modal-form-input-valid": !meta.error,
                    "duetron-modal-form-input-invalid": meta.error && meta.touched
                })} {...fields} {...props} type="number" min="0" />
                {meta.error && meta.touched && (
                    <small className="text-xs mt-2 text-red-600">{meta.error}</small>
                )}
            </label>
            <div className="flex-none grid grid-cols-2 pl-2 gap-x-2 w-24 h-10 mt-7">
                <button 
                disabled={fields.value < 1}
                type="button"
                    onClick={descrement}
                className="flex items-center justify-center col-span-1 rounded text-center bg-backdrop text-sm text-white disabled:bg-opacity-50">-</button>
                <button type="button" onClick={increment} className="flex items-center justify-center w-full rounded text-center bg-backdrop text-sm text-white disabled:bg-opacity-50">+</button>
            </div>
        </div>
	)
}