import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const Navigator = () => {
  const navigate = useNavigate();
  const nextPage = () => {
    navigate(1);
  };
  const prevPage = () => {
    navigate(-1);
  };
  return (
    <nav className="flex items-center gap-x-4">
      <button
        onClick={prevPage}
        className="h-8 w-8 flex items-center justify-center rounded-full bg-sidebar bg-opacity-70"
      >
        <IoIosArrowBack className="h-6 w-6 text-white" />
      </button>
      <button
        onClick={nextPage}
        className="h-8 w-8 flex items-center justify-center rounded-full bg-sidebar bg-opacity-70"
      >
        <IoIosArrowForward className="h-6 w-6 text-white" />
      </button>
    </nav>
  );
};

export default Navigator;
