import { IoIosApps } from "react-icons/io";
import StatsCard from "./StatsCard";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { MdOutlineDeviceHub } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import store from "store";
import { useSelector } from "react-redux";
import {
  setStats,
  resetStats,
  startLoading,
  stopLoading,
} from "store/StatisticSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import StatsService from "services/StatsService";
import { useTranslation } from "react-i18next";

export const StatsBoard = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useSelector((state) => state.stats);

  const dataLoad = async () => {
    store.dispatch(startLoading());
    await StatsService.getDashboardStats()
      .then((res) => {
        const result = res.data.data;
        store.dispatch(setStats(result));
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };

  useComponentDidMount(() => {
    dataLoad();
  });

  useComponentWillUnmount(() => {
    store.dispatch(resetStats());
  });

  return (
    <>
      <StatsCard
        icon={<IoIosApps size={24} />}
        title={t("routes.apps.title")}
        detail={isLoading ? "..." : data?.applicationCount ?? 0}
        color="red"
      />
      <StatsCard
        icon={<HiOutlineStatusOnline size={24} />}
        title={t("routes.states.title")}
        detail={isLoading ? "..." : data?.stateCount ?? 0}
        color="orange"
      />
      <StatsCard
        icon={<MdOutlineDeviceHub size={24} />}
        title={t("routes.devices.title")}
        detail={isLoading ? "..." : data?.deviceCount ?? 0}
        color="blue"
      />
      <StatsCard
        icon={<FaUsers size={24} />}
        title={t("routes.users.title")}
        detail={isLoading ? "..." : data?.userCount ?? 0}
        color="green"
      />
    </>
  );
};

export default StatsBoard;
