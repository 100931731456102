import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: [],
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    append: (state, action) => {
      state.modals = [...state.modals, action.payload];
    },
    destroy: state => {
      const modalList = [...state.modals];
      modalList.pop();
      state.modals = modalList;
    },
    destroyAll: state => {
      state.modals = [];
    },
  },
});

export const { append, destroy, destroyAll } = modalSlice.actions;
export default modalSlice.reducer;
