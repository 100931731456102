import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import { Outlet } from "react-router-dom";
import { useAuth } from 'misc/auth';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSettings } from "misc/settings";

export const UserLayout = () => {
  const {loginUser, logoutUser, isAuthenticated} = useAuth();
  const {data, getSettings, error} = useSettings();
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  const auth = useSelector((state) => state.auth);
  const showSidebar = true;
  useEffect(() => {
    if(!authenticated) {
      logoutUser();
      navigate('/login')
    }else {
      if(!auth.isAuthenticated) {
        loginUser();
        if(data.id === null && !error) {
          getSettings();
        }
      }
    }
  }, [authenticated, auth, navigate, loginUser, logoutUser, data, error, getSettings])
    return (<>
    {showSidebar && <Sidebar className="transition-all" />}
    <main className="flex-auto relative overflow-auto bg-gradient-to-b from-backdrop to-footer scrollbar scrollbar-rounded scrollbar-thumb-rounded-md scrollbar-thumb-active scrollbar-track-black">
      <Navbar />
      <div className="px-4 pt-4 mt-16 pb-16">
        <Outlet />
      </div>
    </main>
    </>)
}

export default UserLayout;