import { useModals, destroyModal } from "./ModalHook";
import modalList from "./ModalList";

const Modal = () => {
  const modals = useModals();
  return (
    <div className="fixed flex inset-0 bg-black/70 items-center justify-center z-50 transition-all ease-in-out duration-300">
      {modals.map((m) => {
        const findModal = modalList.find((mL) => mL.name === m.name);
        return (
          <div
            className="hidden last:block bg-sidebar rounded-md shadow-lg shadow-backdrop border border-active p-1"
            key={`modal_${findModal.name}`}
          >
            <findModal.element data={m.data} close={destroyModal} />
          </div>
        );
      })}
    </div>
  );
};

export default Modal;
