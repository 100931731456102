import AccountChangePassword from "./components/AccountChangePassword";
import DeviceAssignment from "./components/DeviceAssignment";
import StateAssignment from "./components/StateAssignment";
import UserChangePassword from "./components/UserChangePassword";

const modalList = [
  {
    name: "account-password",
    element: AccountChangePassword,
  },
  {
    name: "user-password",
    element: UserChangePassword,
  },
  {
    name: "device-assignment",
    element: DeviceAssignment,
  },
  {
    name: "state-assignment",
    element: StateAssignment,
  },
];

export default modalList;
