import * as Yup from "yup";
import store from "store";
import { startLoading, stopLoading, startReload } from "store/DeviceSlice";
import DeviceService from "services/DeviceService";
import { Button, DarkInput } from "misc/form";
import { useNotification } from "misc/notification";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { FiSave } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { TbTransform } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useSelector } from "react-redux";

const DeviceEditForm = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "devices.forms.addDevice",
  });
  const { success } = useNotification();
  const navigate = useNavigate();
  const formikRef = useRef();
  const { selectedAccessDevice } = useSelector((state) => state.devices);
  const initialValues = () => ({
    id: selectedAccessDevice.id ?? null,
    address: selectedAccessDevice.address ?? null,
    deviceName: selectedAccessDevice.deviceName ?? null,
    deviceSystemId: selectedAccessDevice.deviceSystemId ?? null,
    info: selectedAccessDevice.info ?? null,
  });
  const DeviceUpdateValidation = Yup.object().shape({
    id: Yup.number().required(),
    address: Yup.string()
      .required(t("address.validations.required"))
      .test(
        "address-schema",
        t("address.validations.address-schema"),
        (value, context) => {
          return (
            context.originalValue &&
            context.originalValue.startsWith("Access Engine.Devices.")
          );
        }
      ),
    deviceSystemId: Yup.string().required(
      t("deviceSystemId.validations.required")
    ),
    deviceName: Yup.string().required(t("deviceName.validations.required")),
    info: Yup.string().nullable(),
  });
  const handleTransformAddress = (val) => {
    const addressFormat = val.startsWith("Access Engine.Devices.");
    if (!addressFormat) {
      formikRef.current.setFieldValue(
        "address",
        `Access Engine.Devices.${val}.State`
      );
    }
  };
  return (
    <div className="col-span-1 p-4 bg-sidebar rounded-md  transition-all">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues()}
        validationSchema={DeviceUpdateValidation}
        onSubmit={async (values, actions) => {
          store.dispatch(startLoading());
          await DeviceService.updateForAccess(values)
            .then((res) => {
              const data = res.data.data;
              store.dispatch(startReload());
              success(`${data.deviceName} başarıyla güncellendi`);
              navigate("/devices");
            })
            .catch((error) => {
              error.handleGlobally && error.handleGlobally();
            })
            .finally(() => {
              store.dispatch(stopLoading());
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, values }) => (
          <Form className="py-4 px-2">
            <DarkInput
              name="deviceName"
              label={t("deviceName.label")}
              placeholder={t("deviceName.placeHolder")}
            />
            <div className="flex gap-x-2 justify-center items-center">
              <div className="flex-1">
                <DarkInput
                  name="address"
                  label={t("address.label")}
                  placeholder={t("address.placeHolder")}
                />
              </div>
              <div className="flex-none pt-4">
                <Button
                  type="button"
                  color="warning"
                  size="icon"
                  onClick={() => handleTransformAddress(values.address)}
                  disabled={values.address.length === 0}
                >
                  <TbTransform size={18} />
                </Button>
              </div>
            </div>
            <DarkInput
              name="deviceSystemId"
              label={t("deviceSystemId.label")}
              placeholder={t("deviceSystemId.placeHolder")}
            />
            <DarkInput
              name="info"
              label={t("info.label")}
              placeholder={t("info.placeHolder")}
            />
            <div className="flex justify-end items-right text-lg pb-2 border-b-sidebar text-link font-medium col-span-3 gap-x-2">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                color="green"
              >
                {!isSubmitting && <FiSave className="mr-2 w-5 h-5" />}{" "}
                {t("update", {
                  keyPrefix: "global.buttons",
                })}
              </Button>
              <Button
                type="button"
                color="error"
                onClick={() => navigate("/devices")}
              >
                <MdOutlineCancel className="mr-2 w-5 h-5 transition-all" />{" "}
                {t("cancel", {
                  keyPrefix: "global.buttons",
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeviceEditForm;
