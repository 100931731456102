import { NavLink } from "react-router-dom";
import { userRoutes } from "routes";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {IoIosArrowForward} from "react-icons/io"
import { useTranslation } from 'react-i18next';

const ActionBar = () => {
  const {t} = useTranslation();
  const menuItems = userRoutes.filter(f => f.showTitle === true);
  const breadcrumbs = useBreadcrumbs(menuItems);
  return (
    <div className="mr-auto ml-4 relative">
      <div className="inline-flex bg-sidebar  bg-opacity-70 px-4 py-2 mr-2 rounded-md text-link actionNavigator">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
          <NavLink key={match.pathname} to={match.pathname} className="inline-flex actionNavigatorLink">
             {match.route.icon !== null && (<match.route.icon className="mr-2" size={24} />)} <span>{t(breadcrumb.props.children)}</span> {index < breadcrumbs.length - 1 && <IoIosArrowForward className="mx-2" size={24} />}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ActionBar;
