import { Menu, Transition } from "@headlessui/react";
import { HiOutlineCommandLine } from "react-icons/hi2";
import { CgSpinnerTwo } from "react-icons/cg";
import {
  VscDebugStart,
  VscDebugRestart,
  VscStopCircle,
  VscDebugPause,
} from "react-icons/vsc";
import { Fragment } from "react";
import { Button } from "misc/form";
import { useServices } from "misc/services";
import { useTranslation } from "react-i18next";

export const ServiceCommand = ({ serviceId }) => {
  const { t } = useTranslation();
  const { start, stop, pause, restart, isSending } = useServices();
  return (
    <Menu as="nav" className={"relative ml-2"}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`flex items-center px-4 py-2 rounded-md text-gray-300 ${
              open ? "bg-active" : "bg-sidebar"
            }`}
          >
            <span className="text-sm font-semibold mr-2">
              {isSending ? (
                <CgSpinnerTwo size={24} className="text-white animate-spin" />
              ) : (
                <HiOutlineCommandLine size={24} />
              )}
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={
                "absolute p-1 top-full right-0 w-48 bg-active text-gray-300 rounded translate-y-2 z-50"
              }
            >
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 gap-x-2 rounded`}
                    onClick={() => stop(serviceId)}
                  >
                    <VscStopCircle
                      size={24}
                      className="text-red-600 flex-none"
                    />
                    <span className="flex-1 text-left">
                      {t("services.commands.stop")}
                    </span>
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 gap-x-2 rounded`}
                    onClick={() => start(serviceId)}
                  >
                    <VscDebugStart
                      size={24}
                      className="text-green-600 flex-none"
                    />
                    <span className="flex-1 text-left">
                      {t("services.commands.start")}
                    </span>
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 gap-x-2 rounded`}
                    onClick={() => pause(serviceId)}
                  >
                    <VscDebugPause
                      size={24}
                      className="text-gray-200 flex-none"
                    />
                    <span className="flex-1 text-left">
                      {t("services.commands.pause")}
                    </span>
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 gap-x-2 rounded`}
                    onClick={() => restart(serviceId)}
                  >
                    <VscDebugRestart
                      size={24}
                      className="text-blue-600 flex-none"
                    />
                    <span className="flex-1 text-left">
                      {t("services.commands.restart")}
                    </span>
                  </Button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ServiceCommand;
