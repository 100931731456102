import Table from "misc/table";
import { useSelector } from "react-redux";
import store from "store";
import {
  setUsers,
  startLoading,
  stopLoading,
  addUser,
  deleteUser,
} from "store/UserSlice";
import UserService from "services/UserService";
import { Button, DarkInput, Radio } from "misc/form";
import { FiKey, FiTrash2, FiUserPlus, FiUserX, FiSave } from "react-icons/fi";
import { createModal } from "misc/modals/ModalHook";
import { useState } from "react";
import classNames from "classnames";
import { Formik, Form } from "formik";
import UserCreateValidation from "validations/UserCreateValidation";
import { useTranslation } from "react-i18next";
import { useConfirm } from "misc/confirm";

const Users = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  // Get user list
  const { users } = useSelector((state) => state.users);

  // Get authenticated user role
  const { role, username } = useSelector((state) => state.auth);

  // Create password change dialog
  const passwordDialog = (user) => createModal("user-password", user);

  // Create new user form showing state
  const [addForm, setAddForm] = useState(false);
  const toggleForm = () => {
    setAddForm(!addForm);
  };

  // Load users from api
  const getUsers = async () => {
    store.dispatch(startLoading());
    await UserService.getList()
      .then((res) => {
        const data = res.data.data;
        store.dispatch(setUsers(data));
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };

  // Delete selected user
  const confirmDelete = (user) => {
    confirm(
      t("user.deleteUser.question", { username: user.username }),
      async () => {
        store.dispatch(startLoading());
        await UserService.deleteUser(user.id)
          .then(() => {
            store.dispatch(deleteUser(user.id));
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );
  };

  // Check users is loaded from api
  if (users.length === 0 || users === null) {
    getUsers();
  }

  // Create table heads
  const heads = [
    {
      title: "#",
      style: "w-8",
    },
    {
      title: t("user.table.username"),
      style: "",
      sortable: true,
    },
    {
      title: t("user.table.info"),
      style: "",
    },
    {
      title: t("user.table.role"),
      style: "w-28",
    },
    {
      title: t("user.table.action"),
      style: "w-28 text-center items-center",
    },
  ];

  return (
    <div className="grid grid-cols-1">
      <div className="flex justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
        <Button
          type="button"
          color={addForm ? "error" : "default"}
          onClick={toggleForm}
        >
          {addForm ? (
            <FiUserX className="mr-2 w-5 h-5 transition-all" />
          ) : (
            <FiUserPlus className="mr-2 w-5 h-5 transition-all" />
          )}
          {addForm ? t("global.buttons.cancel") : t("global.buttons.add")}
        </Button>
      </div>
      <div
        className={classNames({
          "grid gap-x-4": true,
          "grid-cols-3": addForm,
          "grid-cols-2": !addForm,
        })}
      >
        <div className="col-span-2 transition-all">
          <Table
            head={heads}
            body={users.map((user) => [
              user.id,
              user.username,
              user.info,
              <div
                className={classNames({
                  "text-center p-2 rounded-full": true,
                  "bg-orange-600 text-black": user.isAdmin,
                  "bg-blue-600 text-white": !user.isAdmin,
                })}
              >
                {user.isAdmin ? "Admin" : "User"}
              </div>,
              <div className="grid grid-cols-2 gap-x-4">
                {user.isAdmin ? (
                  role === "Admin" && (
                    <Button
                      type="button"
                      color="warning"
                      size="icon"
                      onClick={() => passwordDialog(user)}
                    >
                      <FiKey size={18} />
                    </Button>
                  )
                ) : (
                  <Button
                    type="button"
                    color="warning"
                    size="icon"
                    onClick={() => passwordDialog(user)}
                  >
                    <FiKey size={18} />
                  </Button>
                )}
                {user.isAdmin
                  ? role === "Admin" &&
                    user.username !== username && (
                      <Button
                        type="button"
                        color="error"
                        size="icon"
                        onClick={() => confirmDelete(user)}
                      >
                        <FiTrash2 size={18} />
                      </Button>
                    )
                  : user.username !== username && (
                      <Button
                        type="button"
                        color="error"
                        size="icon"
                        onClick={() => confirmDelete(user)}
                      >
                        <FiTrash2 size={18} />
                      </Button>
                    )}
              </div>,
            ])}
            actionStyle={""}
            actionMenu={null}
            searchable={true}
            searchPlaceHolder={t("user.search.placeHolder")}
          ></Table>
        </div>
        {addForm && (
          <div className="col-span-1 p-4 bg-sidebar rounded-md  transition-all">
            <Formik
              initialValues={{
                username: "",
                password: "",
                confirmPassword: "",
                info: "",
                isAdmin: false,
              }}
              validationSchema={UserCreateValidation}
              onSubmit={async (values, actions) => {
                store.dispatch(startLoading());
                await UserService.addUser(values)
                  .then((res) => {
                    const data = res.data.data;
                    store.dispatch(addUser(data));
                    actions.resetForm();
                    toggleForm();
                  })
                  .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                  })
                  .finally(() => {
                    store.dispatch(stopLoading());
                  });
              }}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form className="py-4 px-2">
                  <DarkInput
                    name="username"
                    label={t("user.addUser.form.username.label")}
                    placeholder={t("user.addUser.form.username.placeHolder")}
                  />
                  <DarkInput
                    name="password"
                    autoComplete="new-password"
                    label={t("user.addUser.form.password.label")}
                    placeholder={t("user.addUser.form.password.placeHolder")}
                  />
                  <DarkInput
                    name="confirmPassword"
                    autoComplete="new-password"
                    type="password"
                    label={t("user.addUser.form.confirmPassword.label")}
                    placeholder={t(
                      "user.addUser.form.confirmPassword.placeHolder"
                    )}
                  />
                  {role === "Admin" && (
                    <Radio
                      label={t("user.addUser.form.role.label")}
                      name="isAdmin"
                      options={[
                        {
                          key: true,
                          value: "Admin",
                        },
                        {
                          key: false,
                          value: "User",
                        },
                      ]}
                    />
                  )}
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid || !dirty || isSubmitting}
                    className="flex items-center justify-center px-4 py-2 ml-auto bg-green-800 disabled:bg-green-800 border-green-800 disabled:border-green-800 hover:bg-green-600 border hover:border-green-600 disabled:opacity-50 rounded-md text-sm"
                  >
                    {!isSubmitting && <FiSave className="mr-2 w-5 h-5" />}{" "}
                    {t("global.buttons.save")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
