import logo from "assets/duetron_icon.svg";
import LoginForm from "./LoginForm";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "components/Navbar/LanguageSwitcher";

export const Login = () => {
    const { t } = useTranslation();
  return (<>
  <section className="w-full bg-gradient-to-b from-sidebar to-footer ">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex flex-col items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-36 h-36 mr-2" src={logo} alt="logo" />
            {t('global.title')}
        </div>
        <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 bg-backdrop">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="flex content-center items-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    <span className="flex-auto">{t('login.title')}</span>
                    <div className="flex-none ml-2"><LanguageSwitcher /></div>
                </h1>
                <LoginForm />
            </div>
        </div>
    </div>
  </section>
  </>)
}

export default Login;