import { Button, TagButton } from "misc/form";
import { BiAddToQueue, BiTransferAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSettings } from "misc/settings";
import Table from "misc/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setAccessDevices,
  resetAccessDevices,
  selectId,
  unselectId,
  deleteAccessDevice,
  startLoading,
  stopLoading,
  stopReload,
  startReload,
  selectAccessDevice,
} from "store/DeviceSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import store from "store";
import DeviceService from "services/DeviceService";
import { createModal } from "misc/modals/ModalHook";
import {
  TbApps,
  TbSquareRounded,
  TbSquareRoundedCheckFilled,
  TbSquareRoundedXFilled,
  TbTrash,
  TbEdit,
} from "react-icons/tb";
import { useConfirm } from "misc/confirm";

const DeviceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useSettings();
  const { confirm } = useConfirm();

  const { accessDevices, selectedIds, isLoading, reload } = useSelector(
    (state) => state.devices
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const assingmentDialog = (ids) => createModal("device-assignment", ids);

  useEffect(() => {
    setPageNumber(accessDevices.pageNumber);
    setPageSize(accessDevices.pageSize);
    setSortColumn(accessDevices.order.column);
    setSortDirection(accessDevices.order.sort);
  }, [accessDevices]);

  useEffect(() => {
    if (reload) {
      const idLength = selectedIds.length;
      if (0 < idLength) {
        selectedIds.forEach((id) => store.dispatch(unselectId(id)));
      }
    }
  }, [reload, selectedIds]);

  useEffect(() => {
    const loadData = async () => {
      store.dispatch(startLoading());
      if (data.accessSystem !== null) {
        await DeviceService.getListForAccess({
          params: {
            pageNumber,
            pageSize,
            column: sortColumn,
            sort: sortDirection,
          },
        })
          .then((res) => {
            const result = res.data;
            store.dispatch(setAccessDevices(result));
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
            store.dispatch(stopReload());
          });
      }
    };
    loadData();
  }, [
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    data.accessSystem,
    reload,
  ]);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleSorting = (sortType) => {
    if (sortType === null) {
      setSortColumn("id");
      setSortDirection("desc");
    } else {
      setSortColumn(sortType.column);
      setSortDirection(sortType.orderBy);
    }
  };

  const handleSelect = (id) => {
    const selected = hasSelected(id);
    if (selected) {
      store.dispatch(unselectId(id));
    } else {
      store.dispatch(selectId(id));
    }
  };

  const handleSelectToggle = () => {
    const devicesLength = accessDevices.data.length;
    const idLength = selectedIds.length;

    if (0 < devicesLength && devicesLength === idLength) {
      accessDevices.data.forEach((device) =>
        store.dispatch(unselectId(device.id))
      );
    } else if (0 < devicesLength && 0 < idLength && idLength < devicesLength) {
      accessDevices.data.forEach((device) => {
        const selected = hasSelected(device.id);
        if (!selected) {
          store.dispatch(selectId(device.id));
        }
      });
    } else if (0 < devicesLength && idLength === 0) {
      accessDevices.data.forEach((device) => {
        const selected = hasSelected(device.id);
        if (!selected) {
          store.dispatch(selectId(device.id));
        }
      });
    }
  };

  // Delete selected app
  const confirmDelete = (device) =>
    confirm(
      t("devices.deleteDevice.question", { devicename: device.deviceName }),
      async () => {
        store.dispatch(startLoading());
        await DeviceService.deleteForAccess(device.id)
          .then(() => {
            store.dispatch(deleteAccessDevice(device.id));
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );

  const confirmUnassign = (device, app) =>
    confirm(
      t("devices.unassignDevice.question", {
        devicename: device.deviceName,
        appname: app.application,
      }),
      async () => {
        store.dispatch(startLoading());
        await DeviceService.unassignAccessDevice({
          applicationId: app.id,
          deviceId: device.id,
        })
          .then(() => {
            store.dispatch(startReload());
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );

  const hasSelected = (id) => {
    const i = selectedIds.findIndex((e) => e === id);

    return -1 < i ? true : false;
  };

  useComponentDidMount(() => {
    handlePageChange(1);
  });
  useComponentWillUnmount(() => {
    store.dispatch(resetAccessDevices());
  });

  const parseToggleIcon = () => {
    const devicesLength = accessDevices.data.length;
    const idLength = selectedIds.length;
    if (0 < devicesLength && devicesLength === idLength) {
      return <TbSquareRoundedCheckFilled className="text-primary" size={18} />;
    } else if (0 < devicesLength && 0 < idLength && idLength < devicesLength) {
      return <TbSquareRoundedXFilled className="text-primary" size={18} />;
    } else if (0 < devicesLength && idLength === 0) {
      return <TbSquareRounded size={18} className="text-link" />;
    }
  };

  const selectForEdit = (device) => {
    store.dispatch(selectAccessDevice(device));
    navigate("/devices/edit");
  };
  const heads = [
    {
      title: (
        <div className="flex justify-center text-center">
          <Button size="icon" onClick={handleSelectToggle}>
            {parseToggleIcon()}
          </Button>
        </div>
      ),
      style: "w-8",
      column: "id",
    },
    {
      title: "#",
      style: "w-8",
      column: "id",
    },
    {
      title: t("devices.tables.deviceList.deviceName"),
      style: "",
      sortable: true,
      column: "deviceName",
    },
    {
      title: t("devices.tables.deviceList.deviceAddress"),
      style: "",
      sortable: true,
      column: "address",
    },
    {
      title: t("devices.tables.deviceList.systemId"),
      style: "",
      sortable: true,
      column: "deviceSystemId",
    },
    {
      title: t("devices.tables.deviceList.application"),
      style: "",
      sortable: false,
      column: "id",
    },
    {
      title: t("devices.tables.deviceList.action"),
      style: "w-28 text-center items-center",
    },
  ];

  return (
    <div className="grid grid-cols-1">
      <div className="flex gap gap-x-2 justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
        {0 < selectedIds.length && (
          <Button
            type="button"
            color="error"
            onClick={() => assingmentDialog(selectedIds)}
          >
            <TbApps className="mr-2 w-5 h-5 transition-all" />
            {t("devices.buttons.assignDevicesToApp", {
              count: selectedIds.length,
            })}
          </Button>
        )}
        <Button type="button" color="green" onClick={() => navigate("create")}>
          <BiAddToQueue className="mr-2 w-5 h-5 transition-all" />{" "}
          {t("global.buttons.add")}
        </Button>
        <Button
          type="button"
          color="warning"
          onClick={() => navigate("transfer")}
        >
          <BiTransferAlt className="mr-2 w-5 h-5 transition-all" />{" "}
          {t("devices.buttons.transfer", {
            accessSystem: data.accessSystem === 1 ? "BIS" : "AMS",
          })}
        </Button>
      </div>
      <div className="col-span-2 transition-all">
        <Table
          isLoading={isLoading}
          serverSide={true}
          head={heads}
          searchable={false}
          searchPlaceHolder={"Cihazlarda ara"}
          body={accessDevices.data.map((device, index) => [
            <div className="flex justify-center text-center">
              <Button size="icon" onClick={() => handleSelect(device.id)}>
                {hasSelected(device.id) ? (
                  <TbSquareRoundedCheckFilled
                    className="text-primary"
                    size={18}
                  />
                ) : (
                  <TbSquareRounded size={18} className="text-link" />
                )}
              </Button>
            </div>,
            (accessDevices.pageNumber - 1) * accessDevices.pageSize + 1 + index,
            device.deviceName,
            device.address,
            device.deviceSystemId,
            <div className="flex gap-x-2">
              {device.applications.map((app, index) => {
                return (
                  <TagButton
                    key={index}
                    onClick={() => confirmUnassign(device, app)}
                    color="danger"
                    Icon={TbApps}
                    text={app.application}
                  />
                );
              })}
            </div>,
            <div className="flex justify-center text-center gap-x-2">
              <Button
                type="button"
                color="warning"
                size="icon"
                onClick={() => selectForEdit(device)}
              >
                <TbEdit size={18} />
                <div className="duetron-tooltip group-hover/button:block">
                  {t("global.buttons.edit")}
                </div>
              </Button>
              <Button
                type="button"
                color="error"
                size="icon"
                onClick={() => confirmDelete(device)}
                disabled={0 < device.applications.length}
                style={{
                  pointerEvents: "fill",
                }}
              >
                <TbTrash size={18} />
                <div className="duetron-tooltip group-hover/button:block">
                  {0 < device.applications.length
                    ? t("devices.buttons.disabledDelete")
                    : t("global.buttons.delete")}
                </div>
              </Button>
            </div>,
          ])}
          pagination={{
            firstPage: accessDevices.firstPageNumber,
            lastPage: accessDevices.lastPageNumber,
            nextPage: accessDevices.nextPageNumber,
            pageNumber: accessDevices.pageNumber,
            pageSize: accessDevices.pageSize,
            previousPage: accessDevices.previousPageNumber,
            totalPages: accessDevices.totalPages,
            totalRecords: accessDevices.totalRecords,
          }}
          changePageHandler={(uri) => handlePageChange(uri)}
          changeSortingHandler={(sortType) => handleSorting(sortType)}
        />
      </div>
    </div>
  );
};

export default DeviceList;
