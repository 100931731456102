import ModalHeader from "./ModalHeader";
import { TbApps } from "react-icons/tb";
import AppService from "services/AppService";
import StateService from "services/StateService";
import { useTranslation } from "react-i18next";
import { setApps, startLoading, stopLoading, setError } from "store/AppSlice";
import { startReload } from "store/StateSlice";
import store from "store";
import { useSelector } from "react-redux";
import { useComponentDidMount } from "misc/componentManager";
import { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import classNames from "classnames";
import { Button } from "misc/form";
import { useAlert } from "misc/alert";
import { useNotification } from "misc/notification";
const StateAssignment = ({ data, close }) => {
  const { t } = useTranslation();
  const { apps } = useSelector((state) => state.apps);
  const { success } = useNotification();
  const [assignmentStatus, setAssignmentStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [model, setModel] = useState({
    applicationId: null,
    stateIds: null,
  });
  const { infoAlert } = useAlert();

  const handleChange = (app, ids) => {
    const createModel = {
      applicationId: app.value,
      stateIds: ids,
    };
    setSelectedOption(app);
    setModel(createModel);
  };

  const getApps = async () => {
    store.dispatch(startLoading());
    await AppService.getList()
      .then((res) => {
        const data = res.data.data;
        store.dispatch(setApps(data));
        store.dispatch(setError(false));
      })
      .catch((error) => {
        store.dispatch(setError(true));
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };

  const handleAssignState = async () => {
    setAssignmentStatus(true);
    await StateService.assignAccessStates(model)
      .then((res) => {
        success(t("global.messages.updated"));
        store.dispatch(startReload());
        close();
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        setAssignmentStatus(false);
      });
  };

  useEffect(() => {
    if (0 < apps.length) {
      apps.forEach((app) => {
        const i = options.findIndex((e) => e.value === app.id);
        if (i < 0) {
          setOptions([...options, { value: app.id, label: app.application }]);
        }
      });
    }
  }, [apps, options]);
  useComponentDidMount(() => {
    getApps();
  });
  return (
    <div className="w-[700px]">
      <ModalHeader>
        <TbApps size={24} className="text-primary" />{" "}
        {t("states.forms.assignState.title")}
      </ModalHeader>
      <div className="grid grid-cols-1 px-2">
        <div className="duetron-form-label pl-1 mt-2 text-xs font-bold">
          {t("states.forms.assignState.selectApp")}
        </div>
        <Select
          placeholder={t("misc.select.placeHolder")}
          value={selectedOption}
          options={apps.map((app) => {
            return {
              value: app.id,
              label: app.application,
            };
          })}
          onChange={(val) => handleChange(val, data)}
          classNames={{
            menuButton: ({ isDisabled }) =>
              classNames({
                "flex text-sm text-link border border-footer rounded shadow-sm transition-all duration-300 focus:outline-none": true,
                "bg-gray-200": isDisabled,
                "bg-backdrop text-link hover:border-primary": !isDisabled,
              }),
            menu: "absolute z-10 w-full bg-backdrop shadow-lg border border-sidebar rounded py-1 mt-1.5 text-sm text-link",
            listItem: ({ isSelected }) =>
              classNames({
                "block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded": true,
                "text-link bg-sidebar": isSelected,
                "text-link hover:bg-sidebar hover:text-link": !isSelected,
              }),
            tagItem: ({ isSelected, item }) =>
              `bg-sidebar text-primary flex rounded-md flex items-center`,
            tagItemText: "text-link ml-2",
            tagItemIconContainer:
              "ml-2 hover:bg-primary hover:text-backdrop p-2 rounded-full cursor-pointer",
          }}
        />
        {selectedOption &&
          infoAlert(
            t("states.forms.assignState.selectedWarning", {
              count: data?.length,
              appName: selectedOption.label,
            })
          )}
        {selectedOption && (
          <div className="flex mt-2 w-full justify-end">
            <Button
              type="button"
              onClick={handleAssignState}
              color="green"
              size="default"
              loading={assignmentStatus}
            >
              {t("states.forms.assignState.startTransfer", {
                appName: selectedOption.label,
              })}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StateAssignment;
