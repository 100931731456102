import { useEffect, useState } from "react";
import {FaSortDown, FaSortUp, FaSort} from "react-icons/fa"
import {CgSpinnerTwo} from "react-icons/cg"
import { useTranslation } from 'react-i18next';
import TablePagination from "./TablePagination";
import { Block } from 'notiflix/build/notiflix-block-aio';

function Table({
  head,
  body,
  serverSide = false,
  actionStyle = "",
  actionMenu = false,
  searchable = false,
  searchPlaceHolder = "",
  isLoading=false,
  pagination = null,
  changePageHandler,
  changeSortingHandler
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if(isLoading) {
      Block.standard('.duetron-table', t('global.loading'), {
        backgroundColor: 'rgba(0,0,0,0.8)',
      });
    }else {
      Block.remove('.duetron-table')
    }
  }, [isLoading, t])
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState(null);
  const filteredData = !serverSide ? (body && body.filter(
		items => items.some(
			item => (item?.key || item?.props?.searchableText || item).toString().toLocaleLowerCase('TR').includes(search.toLocaleLowerCase('TR'))
		)
	).sort((a, b) => {
		if (sorting?.orderBy === 'asc') {
			return (a[sorting.key]?.key || a[sorting.key]?.props?.searchableText || a[sorting.key]).toString().localeCompare(b[sorting.key]?.key || b[sorting.key]?.props?.searchableText || b[sorting.key])
		}else if(sorting?.orderBy === 'desc') {
			return b[sorting.key].toString().localeCompare(a[sorting.key])
		}
	})) : body;

  const sortingHandler = (data) => {
    setSorting({
      key: data.key,
      orderBy: data.orderBy
    })
    if(serverSide) {
      changeSortingHandler({
        column: data.column,
        orderBy: data.orderBy
      })
    }
  }

  const cancelSorting = () => {
    setSorting(null);
    if(serverSide) {
      changeSortingHandler(null);
    }
  }
  const hideLoading = true;
  return (
    <>
      {searchable && (
        <div className="mb-2 flex gap-x-2">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={searchPlaceHolder}
            className="h-10 border border-gray-800 shadow-md border-opacity-5 outline-none rounded text-sm px-4 bg-sidebar text-gray-300 text-opacity-70 w-full"
          />
          {sorting && (
						<button
							onClick={() => cancelSorting()}
							className="h-10 rounded whitespace-nowrap border border-red-500 text-red-500 text-sm px-4">
							{t('misc.table.cancelSorting')}
						</button>
					)}
        </div>
      )}
      <div className="w-full border rounded p-1 bg-sidebar border-gray-900 border-opacity-5 shadow-md duetron-table">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-900 bg-opacity-5">
              {head.map((h, key) => (
                <th
                  key={`table_head_${key}`}
                  className={`text-left bg-backdrop text-sm font-semibold text-link p-3 border-b border-r border-gray-300 border-opacity-5 ${h.style}`}
                >
                  <div className="inline-flex items-center gap-x-2">
										{h.title}
										{h.sortable && (
											<button onClick={() => {
												if (sorting?.key === key) {
													sortingHandler({
														key,
                            column: h.column,
														orderBy: sorting.orderBy === 'asc' ? 'desc' : 'asc'
													})
												} else {
													sortingHandler({
														key,
                            column: h.column,
														orderBy: 'asc'
													})
												}
											}}>
												{sorting?.key === key && (
													sorting.orderBy === 'asc' ? <FaSortDown size={14}/> : <FaSortUp size={14}/>
												)}
												{sorting?.key !== key && <FaSort size={14}/>}
											</button>
										)}
									</div>
                </th>
              ))}
              {actionMenu && (
                <th className={`text-left bg-backdrop text-sm font-semibold text-link p-3 border-b border-gray-300 border-opacity-5 ${actionStyle}`}>
                  İşlem
                </th>
              )}
            </tr>
          </thead>
          <tbody>
          {0 < body?.length && filteredData.map((items, _) => (
              <tr
                key={`table_row_${_}`}
                className="group/table border-b border-gray-800 border-opacity-5 text-sm text-link"
              >
                {items.map((item, key) => (
                  <td
                    key={`row_column_${key}`}
                    className="p-3 text-xs group-hover/table:bg-backdrop/30 border-b border-b-backdrop"
                  >
                    {item}
                  </td>
                ))}
                {actionMenu && (
                  <td className="p-3 text-sm text-center group-hover/table:bg-backdrop border-b border-b-backdrop">{actionMenu}</td>
                )}
              </tr>
            ))}
            {((!filteredData || filteredData?.length ===0) && !isLoading) && (
              <tr className="group/table border-b border-gray-800 border-opacity-5 text-sm text-center">
                <td colSpan={head.length} className="py-4">{t('misc.table.dataNotFound')}</td>
              </tr>
            )}
            {!hideLoading && <tr className="group/table border-b border-gray-800 border-opacity-5 text-sm text-center">
            <td colSpan={head.length} className="py-4">
              <div className="flex flex-row items-center justify-center">
              <CgSpinnerTwo className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" /> {t('global.loading')}...
              </div>
            </td>  
          </tr>}
          </tbody>
        </table>
        {pagination !== null && <TablePagination pagination={pagination} changePageHandler={(url) => changePageHandler(url)} />}
      </div>
    </>
  );
}

export default Table;
