import { useField } from "formik";
import classNames from "classnames";

export default function Input({ label, ...props }) {
	const [fields, meta] = useField(props)
	return (
		<label className="flex flex-col">
			<div className="duetron-form-label">{label}</div>
			<input className={classNames({
				"duetron-form-input": true,
				"duetron-form-input-valid": !meta.error,
				"duetron-form-input-invalid": meta.error && meta.touched
			})} {...fields} {...props}/>
			{meta.error && meta.touched && (
				<small className="text-xs mt-2 text-red-600">{meta.error}</small>
			)}
		</label>
	)
}