import { LocalStorage, SessionStorage } from "storage-manager-js"
import {STORAGE_TYPE} from "configs";

class StorageService {
    storage = () => {
        return STORAGE_TYPE === 'local' ? LocalStorage : SessionStorage;
    }
}

export const service = new StorageService();
export default service;