import ActionBar from "./ActionBar";
import LanguageSwitcher from "./LanguageSwitcher";
import Navigator from "./Navigator";
import UserAction from "./UserAction";
export const Navbar = () => {
    return (
        <nav className="h-16 inline-flex app-navbar items-center justify-between px-4 fixed z-10 bg-black bg-opacity-80">
            <Navigator />
            <ActionBar />
            <LanguageSwitcher />
            <UserAction />
        </nav>
    )
}

export default Navbar;