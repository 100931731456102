import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./ModalSlice";
import authReducer from "./AuthSlice";
import settingsReducer from "./SettingsSlice";
import userReducer from "./UserSlice";
import appReducer from "./AppSlice";
import dynamicInputReducer from "./DynamicInputSlice";
import deviceReducer from "./DeviceSlice";
import stateReducer from "./StateSlice";
import logReducer from "./LogSlice";
import statisticReducer from "./StatisticSlice";
import serviceReducer from "./ServiceSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    auth: authReducer,
    settings: settingsReducer,
    users: userReducer,
    apps: appReducer,
    devices: deviceReducer,
    inputs: dynamicInputReducer,
    states: stateReducer,
    logs: logReducer,
    stats: statisticReducer,
    services: serviceReducer,
  },
});

export default store;
