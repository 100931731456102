import classNames from "classnames";

export const StatsCard = ({ icon, title, detail, color = null }) => {
  return (
    <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-sidebar shadow-md">
      <div className="p-4 flex items-center">
        <div
          className={classNames({
            "p-3 rounded-full mr-4": true,
            "text-link bg-backdrop": color === null,
            [`bg-${color}-600 text-${color}-200`]: color !== null,
          })}
        >
          {icon}
        </div>
        <div>
          <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            {title}
          </p>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
            {detail}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
