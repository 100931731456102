import axios from 'axios';
import {API_BASE_URL} from "configs";
import AxiosErrorHandler from 'misc/error/AxiosErrorHandler';
import AuthService from "./AuthService";

class ApiService {
    get = async (url, params = {}) => {
      return await this.request().get(url, params);
    };
    post = async (url, data = {}) => {
      return await this.request().post(url, data);
    };
    put = async (url, data = {}) => {
      return await this.request().put(url, data);
    }
    delete = async (url, params = {}) => {
      return await this.request().delete(url, params);
    }

    request = () => {

      const instance = axios.create({
        baseURL: API_BASE_URL
      });

      if (AuthService.hasToken()) {
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + AuthService.token();
      }

      instance.interceptors.response.use(async (response) => {
        return response
      }, (error) => {
        error.handleGlobally = AxiosErrorHandler(error)
        return Promise.reject(error);
      })

      return instance;
    }
  }
  export const service = new ApiService();
  export default service;