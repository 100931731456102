import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  username: null,
  token: null,
  role: null,
  expire: null
};

export const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.username = action.payload.username;
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.expire = action.payload.expire;
        },
        logout: (state, action) => {
            state.isAuthenticated = false;
            state.username = null;
            state.token = null;
            state.role = null;
            state.expire = null;
        }
    }
})

export const selectUser = (state) => state.auth;
export const { login, logout } = authSlice.actions;
export default authSlice.reducer;