import * as Yup from 'yup';
import i18n from "i18n";

export const AccountPasswordValidation = Yup.object().shape({
    currentPassword: Yup
                .string()
                .required(i18n.t('auth.form.passwordChange.currentPassword.validations.required')),
    newPassword: Yup
                .string()
                .required(i18n.t('auth.form.passwordChange.newPassword.validations.required')),
    confirmPassword: Yup
                .string()
                .required(i18n.t('auth.form.passwordChange.confirmPassword.validations.required')),
});

export default AccountPasswordValidation;