import { useServices } from "misc/services";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import { useTranslation } from "react-i18next";
import Table from "misc/table";
import ServiceCommand from "./ServiceCommand";

export const ServiceTable = () => {
  const { t } = useTranslation();
  const { data, getServices, resetServiceList, isLoading } = useServices();

  useComponentDidMount(() => {
    getServices();
  });

  useComponentWillUnmount(() => {
    resetServiceList();
  });

  const statuses = (status) => {
    switch (status) {
      case "Stopped":
        return t("services.status.stopped");
      case "StartPending":
        return t("services.status.startPending");
      case "StopPending":
        return t("services.status.stopPending");
      case "Running":
        return t("services.status.running");
      case "ContinuePending":
        return t("services.status.continuePending");
      case "PausePending":
        return t("services.status.pausePending");
      case "Paused":
        return t("services.status.paused");
      default:
        return "";
    }
  };
  const heads = [
    {
      title: "#",
      style: "w-8",
      column: "id",
    },
    {
      title: t("services.table.name"),
      style: "",
      column: "name",
    },
    {
      title: t("services.table.description"),
      style: "",
      column: "description",
    },
    {
      title: t("services.table.status"),
      style: "",
      column: "status",
    },
    {
      title: t("services.table.command"),
      style: "",
      column: "command",
    },
  ];

  return (
    <Table
      isLoading={isLoading}
      serverSide={false}
      head={heads}
      searchable={true}
      searchPlaceHolder={t("services.search.placeHolder")}
      body={data.map((service, index) => [
        index + 1,
        service.name,
        service.description,
        statuses(service.status),
        <ServiceCommand serviceId={service.id} />,
      ])}
    />
  );
};

export default ServiceTable;
