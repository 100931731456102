import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  reload: false,
  accessDevices: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "desc",
    },
  },
  selectedDevice: null,
  selectedDevices: [],
  systemDevices: {
    data: [],
    firstPage: null,
    lastPage: null,
    nextPage: null,
    pageNumber: 1,
    pageSize: 10,
    previousPage: null,
    totalPages: null,
    totalRecords: null,
    order: {
      column: "id",
      sort: "asc",
    },
  },
  selectedIds: [],
};

export const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setAccessDevices: (state, action) => {
      state.accessDevices = action.payload;
    },
    resetAccessDevices: (state) => {
      state.accessDevices = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "desc",
        },
      };
    },
    setSystemDevices: (state, action) => {
      state.systemDevices = action.payload;
    },
    resetSystemDevices: (state) => {
      state.systemDevices = {
        data: [],
        firstPage: null,
        firstPageNumber: null,
        lastPage: null,
        lastPageNumber: null,
        nextPage: null,
        nextPageNumber: null,
        pageNumber: 1,
        pageSize: 10,
        previousPage: null,
        previousPageNumber: null,
        totalPages: null,
        totalRecords: null,
        order: {
          column: "id",
          sort: "asc",
        },
      };
    },
    addAccessDevice: (state, action) => {
      state.accessDevices = [...state.accessDevices.data, action.payload];
    },
    updateAccessDevice: (state, action) => {
      state.accessDevices.data = [
        ...state.accessDevices.data.map((device) =>
          device.id === action.payload.id ? action.payload : device
        ),
      ];
    },
    selectAccessDevice: (state, action) => {
      state.selectedAccessDevice = action.payload;
    },
    unselectAccessDevice: (state) => {
      state.selectedAccessDevice = null;
    },
    deleteAccessDevice: (state, action) => {
      state.accessDevices.data = [
        ...state.accessDevices.data.filter((x) => x.id !== action.payload),
      ];
    },
    startReload: (state) => {
      state.reload = true;
    },
    stopReload: (state) => {
      state.reload = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    selectSystemDevice: (state, action) => {
      const i = state.selectedDevices.findIndex(
        (e) => e.id === action.payload.id
      );
      if (i < 0) {
        state.selectedDevices = [...state.selectedDevices, action.payload];
      }
    },
    unselectSystemDevice: (state, action) => {
      state.selectedDevices = [
        ...state.selectedDevices.filter((x) => x.id !== action.payload),
      ];
    },
    selectId: (state, action) => {
      const i = state.selectedIds.findIndex((e) => e === action.payload);
      if (i < 0) {
        state.selectedIds = [...state.selectedIds, action.payload];
      }
    },
    unselectId: (state, action) => {
      state.selectedIds = [
        ...state.selectedIds.filter((x) => x !== action.payload),
      ];
    },
  },
});

export const {
  addAccessDevice,
  updateAccessDevice,
  deleteAccessDevice,
  setAccessDevices,
  selectAccessDevice,
  unselectAccessDevice,
  startLoading,
  stopLoading,
  setSystemDevices,
  resetAccessDevices,
  resetSystemDevices,
  selectSystemDevice,
  unselectSystemDevice,
  selectId,
  unselectId,
  startReload,
  stopReload,
} = deviceSlice.actions;
export default deviceSlice.reducer;
