import store from "store";
import { login, logout } from "store/AuthSlice";
import AuthService from "services/AuthService";
import { useTranslation } from "react-i18next";
import { useNotification } from "misc/notification";

export const useAuth = () => {
  const { t } = useTranslation();
  const { success } = useNotification();
  const loginUser = () => {
    const profile = AuthService.profile();
    store.dispatch(
      login({
        username: profile.username,
        token: profile.token,
        role: profile.role,
        expire: profile.exp,
      })
    );
    success(t("auth.messages.welcome", { username: profile.username }));
  };

  const logoutUser = () => {
    AuthService.logout();
    store.dispatch(logout());
    success(t("auth.messages.goodbye"));
  };
  const isAuthenticated = () => {
    const hasToken = AuthService.hasToken();
    if (hasToken) {
      const profile = AuthService.profile();
      if (+profile.exp * 1000 < Date.now()) return false;
      return true;
    }
    return false;
  };

  return {
    loginUser,
    logoutUser,
    isAuthenticated,
  };
};
