import ApiService from "./ApiService";

class StateService {
  addForAccess = async (values) =>
    await ApiService.post("states/add/access", values);
  updateForAccess = async (values) =>
    await ApiService.put("states/update/access", values);
  deleteForAccess = async (id) =>
    await ApiService.delete(`states/delete/access?id=${id}`, {});
  getStates = async (params) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`states/list/access?${queryParams}`, {});
  };
  assignAccessStates = async (values) =>
    await ApiService.post("states/attach/access", values);
  unassignAccessState = async (params) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.delete(`states/detach/access?${queryParams}`, {});
  };
}

export const service = new StateService();
export default service;
