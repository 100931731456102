import i18n from "i18n";
import { useNotification } from "misc/notification";

const AxiosErrorHandler = (errorData) => {
  const { error } = useNotification();
  switch (errorData.code) {
    case "ERR_NETWORK":
      error(i18n.t("ERR_NETWORK"));
      break;
    case "ERR_BAD_REQUEST":
      const errors = errorData.response.data.errors;
      if (0 < errors.length) {
        errors.map((err) => error(err));
      } else {
        error(errorData.message);
      }
      break;
    default:
      error(errorData.message);
      break;
  }
};

export default AxiosErrorHandler;
