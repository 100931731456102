import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    apps: [],
    selectedApp: null,
    error: false
}

export const appSlice = createSlice({
    name: "apps",
    initialState,
    reducers: {
        setApps: (state, action) => {
            state.apps = action.payload
        },
        addApp: (state, action) => {
            state.apps = [...state.apps, action.payload]
        },
        updateApp: (state, action) => {
            state.apps = [
                ...state.apps.map((app) =>
                    app.id === action.payload.id ? action.payload : app
                )
            ]
        },
        selectApp: (state, action) => {
            state.selectedApp = action.payload
        },
        unselectApp: (state) => {
            state.selectedApp = null
        },
        deleteApp: (state, action) => {
            state.apps = [...state.apps.filter(x => x.id !== action.payload)]
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { addApp, updateApp, deleteApp, setApps, selectApp, unselectApp, startLoading, stopLoading, setError } = appSlice.actions;
export default appSlice.reducer;