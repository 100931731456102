import { Menu, Transition } from "@headlessui/react";
import { CgProfile, CgLogOff } from "react-icons/cg";
import { FiKey } from "react-icons/fi";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forwardRef } from "react";
import { Button } from "misc/form";
import { useAuth } from "misc/auth";
import { createModal } from "misc/modals/ModalHook";
import { useTranslation } from "react-i18next";
import { useConfirm } from "misc/confirm";

const UserAction = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { logoutUser } = useAuth();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const logout = () =>
    confirm(
      t("auth.logout.question"),
      () => ({
        logout: logoutUser(),
        redirect: navigate("/login"),
      }),
      () => null
    );
  const passwordDialog = () => createModal("account-password");
  return (
    <Menu as="nav" className={"relative ml-2"}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`flex items-center px-4 py-2 rounded-md text-gray-300  bg-opacity-70 ${
              open ? "bg-active" : "bg-sidebar"
            }`}
          >
            <CgProfile className="p-px mr-2 text-primary" size={24} />
            <span className="text-sm font-semibold mr-2">{auth.username}</span>
            {open ? (
              <HiChevronUp className="h-6 w-6" />
            ) : (
              <HiChevronDown className="h-6 w-6" />
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={
                "absolute p-1 top-full right-0 w-48 bg-active text-gray-300 rounded translate-y-2"
              }
            >
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 rounded`}
                    onClick={passwordDialog}
                  >
                    <FiKey className="h-5 w-5 p-px mr-2 text-yellow-600" />{" "}
                    {t("auth.title")}
                  </Button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Button
                    type="button"
                    className={`${
                      active && "bg-white bg-opacity-5"
                    } w-full h-10 flex items-center text-sm px-2 rounded`}
                    onClick={logout}
                  >
                    <CgLogOff className="h-5 w-5 p-px mr-2 text-red-600" />{" "}
                    {t("auth.logout.button")}
                  </Button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default forwardRef(UserAction);
