
export const CommandSeperators = [
    {
        value: "s1",
        label: "_"
    },
    {
        value: "s2",
        label: "-"
    },
    {
        value: "s3",
        label: "%"
    },
    {
        value: "s4",
        label: "*"
    },
    {
        value: "s5",
        label: "+"
    },
]