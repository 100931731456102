import { useSelector } from "react-redux";
import store from "store";
import {
  handleChange,
  addFormFields,
  setFormFields,
  removeFormFields,
  resetFormFields,
} from "store/DynamicInputSlice";

export const useDynamicInput = () => {
  const { inputs } = useSelector((state) => state.inputs);
  const changeField = (i, e) => {
    const name = e.target.name;
    const value = e.target.value;
    store.dispatch(handleChange({ i, name, value }));
  };

  const changeFieldManually = (i, name, value) => {
    store.dispatch(handleChange({ i, name, value }));
  };

  const setField = (key, value) => {
    var input = inputs.find((f) => f.key === key && f.value === value);
    if (input === undefined) {
      store.dispatch(setFormFields({ key, value }));
    }
  };

  const addField = () => {
    store.dispatch(addFormFields());
  };

  const removeField = (i) => {
    store.dispatch(removeFormFields(i));
  };

  const resetField = () => {
    store.dispatch(resetFormFields());
  };
  return {
    inputs,
    changeField,
    changeFieldManually,
    addField,
    setField,
    removeField,
    resetField,
  };
};
