import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inputs: []
}

export const dynamicInputSlice = createSlice({
    name: "inputs",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            let values = state.inputs;
            values[action.payload.i][action.payload.name] = action.payload.value;
            state.inputs = values
        }, 
        addFormFields: (state) => {
            state.inputs = [...state.inputs, { key: "", value: ""}]
        },
        setFormFields: (state, action) => {
            const index = state.inputs.findIndex(f => f.key === action.payload.key);
            if(index === -1) {
                state.inputs = [...state.inputs, { key: action.payload.key, value: action.payload.value}]
            }
        },
        removeFormFields: (state, action) => {
            let values = state.inputs;
            values.splice(action.payload, 1);
           state.inputs = values
        },
        resetFormFields: (state) => {
            state.inputs = []
        }
    }
})

export const { handleChange, addFormFields, setFormFields, removeFormFields, resetFormFields } = dynamicInputSlice.actions;
export default dynamicInputSlice.reducer;