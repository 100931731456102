import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    error: false,
    data: {
        id: null,
        accessServer: "",
        accessUsername: "",
        accessPassword: "",
        accessSystem: null,
        integrationType: null,
        opcServer: "",
        keepLogDay: null
    }
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        setSettings: (state, action) => {
            state.data = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const selectSettings = (state) => state.settings;
export const { startLoading, stopLoading, setSettings, setError } = settingsSlice.actions;
export default settingsSlice.reducer;