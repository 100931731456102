import { IoIosApps } from "react-icons/io";
import { MdOutlineDeviceHub, MdHistory } from "react-icons/md";
import { HiOutlineStatusOnline, HiOutlineCog } from "react-icons/hi";
import { FaHome, FaUsers } from "react-icons/fa";
import { VscBracketError, VscServerProcess } from "react-icons/vsc";
import { TbApps, TbEdit, TbError404 } from "react-icons/tb";
import { BiAddToQueue, BiTransferAlt } from "react-icons/bi";
import { BsActivity } from "react-icons/bs";

import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import NotFound from "pages/NotFound";
import Settings from "pages/Settings";
import Apps from "pages/Apps";
import States from "pages/States";
import Devices from "pages/Devices";
import DeviceList from "pages/Devices/components/DeviceList";
import DeviceAddForm from "pages/Devices/components/DeviceAddForm";
import DeviceEditForm from "pages/Devices/components/DeviceEditForm";
import Users from "pages/Users";
import ErrorLogs from "pages/ErrorLogs";
import OperationLogs from "pages/OperationLogs";
import StateLogs from "pages/StateLogs";
import AppAddForm from "pages/Apps/components/AppAddForm";
import AppList from "pages/Apps/components/AppList";
import AppEditForm from "pages/Apps/components/AppEditForm";
import DeviceTransferForm from "pages/Devices/components/DeviceTransferForm";
import StateList from "pages/States/components/StateList";
import StateAddForm from "pages/States/components/StateAddForm";
import StateEditForm from "pages/States/components/StateEditForm";
import ActivityLogs from "pages/ActivityLogs";

export const userRoutes = [
  {
    path: "/",
    title: "routes.dashboard.title",
    breadcrumb: "routes.dashboard.breadcrumb",
    icon: FaHome,
    roles: ["Admin", "User"],
    element: <Dashboard />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/apps",
    title: "routes.apps.title",
    breadcrumb: "routes.apps.breadcrumb",
    icon: IoIosApps,
    roles: ["Admin"],
    element: <Apps />,
    showMenu: true,
    showTitle: true,
    children: [
      {
        path: "",
        title: "routes.apps.children.list.title",
        breadcrumb: "routes.apps.children.list.breadcrumb",
        icon: IoIosApps,
        roles: ["Admin"],
        element: <AppList />,
        showMenu: true,
        showTitle: true,
      },
      {
        path: "create",
        title: "routes.apps.children.create.title",
        breadcrumb: "routes.apps.children.create.breadcrumb",
        icon: TbApps,
        roles: ["Admin"],
        element: <AppAddForm />,
        showMenu: false,
        showTitle: true,
      },
      {
        path: "edit",
        title: "routes.apps.children.edit.title",
        breadcrumb: "routes.apps.children.edit.breadcrumb",
        icon: TbEdit,
        roles: ["Admin"],
        element: <AppEditForm />,
        showMenu: false,
        showTitle: true,
      },
    ],
  },
  {
    path: "/states",
    title: "routes.states.title",
    breadcrumb: "routes.states.breadcrumb",
    icon: HiOutlineStatusOnline,
    roles: ["Admin", "User"],
    element: <States />,
    showMenu: true,
    showTitle: true,
    children: [
      {
        path: "",
        title: "routes.states.children.list.title",
        breadcrumb: "routes.states.children.list.breadcrumb",
        icon: MdOutlineDeviceHub,
        roles: ["Admin", "User"],
        element: <StateList />,
        showMenu: true,
        showTitle: true,
      },
      {
        path: "create",
        title: "routes.states.children.create.title",
        breadcrumb: "routes.states.children.create.breadcrumb",
        icon: BiAddToQueue,
        roles: ["Admin"],
        element: <StateAddForm />,
        showMenu: false,
        showTitle: true,
      },
      {
        path: "edit",
        title: "routes.states.children.edit.title",
        breadcrumb: "routes.states.children.edit.breadcrumb",
        icon: TbEdit,
        roles: ["Admin"],
        element: <StateEditForm />,
        showMenu: false,
        showTitle: true,
      },
    ],
  },
  {
    path: "/devices",
    title: "routes.devices.title",
    breadcrumb: "routes.devices.breadcrumb",
    icon: MdOutlineDeviceHub,
    roles: ["Admin", "User"],
    element: <Devices />,
    showMenu: true,
    showTitle: true,
    children: [
      {
        path: "",
        title: "routes.devices.children.list.title",
        breadcrumb: "routes.devices.children.list.breadcrumb",
        icon: MdOutlineDeviceHub,
        roles: ["Admin", "User"],
        element: <DeviceList />,
        showMenu: true,
        showTitle: true,
      },
      {
        path: "create",
        title: "routes.devices.children.create.title",
        breadcrumb: "routes.devices.children.create.breadcrumb",
        icon: BiAddToQueue,
        roles: ["Admin"],
        element: <DeviceAddForm />,
        showMenu: false,
        showTitle: true,
      },
      {
        path: "transfer",
        title: "routes.devices.children.transfer.title",
        breadcrumb: "routes.devices.children.transfer.breadcrumb",
        icon: BiTransferAlt,
        roles: ["Admin"],
        element: <DeviceTransferForm />,
        showMenu: false,
        showTitle: true,
      },
      {
        path: "edit",
        title: "routes.devices.children.edit.title",
        breadcrumb: "routes.devices.children.edit.breadcrumb",
        icon: TbEdit,
        roles: ["Admin"],
        element: <DeviceEditForm />,
        showMenu: false,
        showTitle: true,
      },
    ],
  },
  {
    path: "/users",
    title: "routes.users.title",
    breadcrumb: "routes.users.breadcrumb",
    icon: FaUsers,
    roles: ["Admin", "User"],
    element: <Users />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/settings",
    title: "routes.settings.title",
    breadcrumb: "routes.settings.breadcrumb",
    icon: HiOutlineCog,
    roles: ["Admin", "User"],
    element: <Settings />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/error-logs",
    title: "routes.logs.error.title",
    breadcrumb: "routes.logs.error.breadcrumb",
    icon: VscBracketError,
    roles: ["Admin"],
    element: <ErrorLogs />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/operation-logs",
    title: "routes.logs.operation.title",
    breadcrumb: "routes.logs.operation.breadcrumb",
    icon: VscServerProcess,
    roles: ["Admin"],
    element: <OperationLogs />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/state-logs",
    title: "routes.logs.state.title",
    breadcrumb: "routes.logs.state.breadcrumb",
    icon: MdHistory,
    roles: ["Admin", "User"],
    element: <StateLogs />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "/activity-logs",
    title: "routes.logs.activity.title",
    breadcrumb: "routes.logs.activity.breadcrumb",
    icon: BsActivity,
    roles: ["Admin", "User"],
    element: <ActivityLogs />,
    showMenu: true,
    showTitle: true,
  },
  {
    path: "*",
    title: "routes.global.404.title",
    breadcrumb: "routes.global.404.breadcrumb",
    icon: TbError404,
    element: <NotFound />,
    showMenu: false,
    showTitle: true,
  },
];

export const guestRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
];

const routes = { userRoutes, guestRoutes };
export default routes;
