import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { unselectSystemDevice } from "store/DeviceSlice";
import { TbTrash } from "react-icons/tb";
import store from "store";
import { Button } from 'misc/form';
import { useEffect } from "react";
import { Block } from 'notiflix/build/notiflix-block-aio';

const SelectedDeviceList = () => {
    const { selectedDevices, isLoading } = useSelector((state) => state.devices);
    const {t} = useTranslation();

    const removeSelectedDevice = (id) => store.dispatch(unselectSystemDevice(id))
    useEffect(() => {
        if(isLoading) {
          Block.standard('.duetron-list', t('global.loading'), {
            backgroundColor: 'rgba(0,0,0,0.8)',
          });
        }else {
          Block.remove('.duetron-list')
        }
    }, [isLoading, t])

    return (<>
        <div className="w-full rounded bg-sidebar p-2">
            <div className="flex w-full justify-between rounded bg-backdrop px-4 py-2 text-left text-sm font-medium text-link mb-2">
                    <div className="flex-1">{t('devices.tables.selectedDevices.deviceAddress')}</div>
                    <div className="flex-1 px-4 border-l border-sidebar">{t('devices.tables.selectedDevices.systemId')}</div>
                    <div className="flex-none pl-4 border-l border-sidebar">{t('devices.tables.selectedDevices.action')}</div>
            </div>
            <div className="duetron-list">
                {selectedDevices.map((device, index) => {
                    const address = `Access Engine.Devices.${device.displayText}.State`;
                    const deviceSystemId = device.id;

                    return (<div key={index} className="flex items-center transition-all duration-300 w-full justify-between rounded bg-backdrop px-4 py-2 text-left text-sm font-medium text-link mb-2">
                        <div className="flex-1">{address}</div>
                        <div className="flex-1 px-4 border-l border-sidebar">{deviceSystemId}</div>
                        <div className="flex justify-center pl-4 border-l border-sidebar">
                            <Button 
                            type="button" 
                            color="error" 
                            size="icon" 
                            onClick={() => removeSelectedDevice(device.id)}
                        ><TbTrash size={18} /></Button> 
                        </div>
                    </div>)
                })}
            </div>
        </div>
    </>)
}

export default SelectedDeviceList;