import store from "store";
import {
  setSettings,
  startLoading,
  stopLoading,
  setError,
} from "store/SettingsSlice";
import { useSelector } from "react-redux";
import SettingsService from "services/SettingsService";
import { useNotification } from "misc/notification";
import { useTranslation } from "react-i18next";

export const useSettings = () => {
  const { data, isLoading, error } = useSelector((state) => state.settings);
  const { success } = useNotification();
  const { t } = useTranslation();

  const getSettings = async () => {
    store.dispatch(startLoading());
    await SettingsService.get()
      .then((res) => {
        const resData = res.data.data;
        saveSettings(resData);
        store.dispatch(setError(false));
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
        store.dispatch(setError(true));
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };
  const updateSettings = async (values) => {
    store.dispatch(startLoading());
    await SettingsService.update(values)
      .then((res) => {
        const resData = res.data.data;
        saveSettings(resData);
        success(t("global.messages.updated"));
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };
  const saveSettings = (data) => {
    store.dispatch(
      setSettings({
        id: Number(data.id),
        accessServer: data.accessServer,
        accessUsername: data.accessUsername,
        accessPassword: data.accessPassword,
        accessSystem: Number(data.accessSystem),
        integrationType: Number(data.integrationType),
        opcServer: data.opcServer,
        keepLogDay: Number(data.keepLogDay),
      })
    );
  };
  const initialValues = (data) => ({
    id: data.id ?? null,
    accessServer: data.accessServer ?? "",
    accessUsername: data.accessUsername ?? "",
    accessPassword: data.accessPassword ?? "",
    accessSystem: data.accessSystem ?? null,
    integrationType: data.integrationType ?? null,
    opcServer: data.opcServer ?? "",
    keepLogDay: data.keepLogDay ?? 0,
  });
  return {
    data,
    error,
    isLoading,
    initialValues,
    getSettings,
    updateSettings,
    saveSettings,
  };
};
