import { TbInfoCircle } from "react-icons/tb"
export const useAlert = () => {
    const successAlert = (message) => {
        return (
            <div className="py-2 px-4 text-xs bg-green-600 text-white rounded mt-2">
                {message}
            </div>
        );
    }

    const errorAlert = (message) => {
        return (
            <div className="py-2 px-4 text-xs bg-red-600 text-white rounded mt-2">
                {message}
            </div>
        );
    }

    const warningAlert = (message) => {
        return (
            <div className="py-2 px-4 text-xs bg-yellow-600 text-white rounded mt-2">
                {message}
            </div>
        );
    }

    const infoAlert = (message) => {
        return (
            <div className="py-2 px-4 text-xs bg-blue-600 text-white rounded mt-2 flex items-center">
                <TbInfoCircle size={24} className="mr-2 float-left" /> {message}
            </div>
        );
    }

    return {
        successAlert,
        errorAlert,
        warningAlert,
        infoAlert
    }
}