import { Formik, Form } from 'formik';
import { DarkInput, Radio, Button, NumberInput } from "misc/form";
import { useSettings } from 'misc/settings';
import { FiSave } from "react-icons/fi"
import * as SettingsContants from "constants/SettingsConstants"
import { useTranslation } from 'react-i18next';

export const SettingsForm = () => {
    const {t} = useTranslation();
    // Access Control System List
    const accessSystems = SettingsContants.AccessSystemList;

    // Integration Type List
    const integrationTypes = SettingsContants.IntegrationTypeList;

    // Integration Types Filtering by Access Control System List
    const activeIntegrationTypes = (system) => {
        return system !== null ? integrationTypes.filter(f => f.systems.includes(system)) : integrationTypes
    }

    // Using Settings Hook
    const { data, isLoading, initialValues, getSettings, updateSettings } = useSettings();

    // Check hook is empty
    if(data.id === null) {
        getSettings();
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues(data)}
            onSubmit={async(values) => updateSettings(values)}
        >
            {({isSubmitting, isValid, dirty, values}) => (
                <Form autoComplete="off">
                    <div className="m-4 flex justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
                       <Button 
                            type="submit"
                            color="green"
                            loading={isSubmitting || isLoading}
                            disabled={!isValid || !dirty}
                        >
                            {(!isSubmitting || !isLoading) && <FiSave className='mr-2 w-5 h-5' />}
                            {t('global.buttons.update')}
                        </Button>
                    </div>
                    <div className="m-4">
                        <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-1 p-4 bg-sidebar rounded-md">
                                <Radio 
                                    label={t('settings.accessControlLabel')} 
                                    name="accessSystem" 
                                    options={accessSystems}
                                />
                            </div>
                            {(values.accessSystem !== null) && (
                                <div className="col-span-1 p-4 bg-sidebar rounded-md">
                                    <Radio 
                                        label={t('settings.integrationType')} 
                                        name="integrationType" 
                                        options={activeIntegrationTypes(values.accessSystem)}
                                    />
                                </div>
                            )}
                            {(values.integrationType !== null && values.integrationType !== 1) && (<>
                                <div className='col-span-2 p-4 bg-sidebar rounded-md'>
                                    <DarkInput 
                                        name="accessServer"
                                        label={t('settings.serverAddress.label')}
                                        placeholder={t('settings.serverAddress.placeHolder')}
                                    />
                                    <DarkInput 
                                        name="accessUsername"
                                        autoComplete="new-password"
                                        label={t('settings.username.label')}
                                        placeholder={t('settings.username.placeHolder')}
                                    />
                                    <DarkInput 
                                        name="accessPassword"
                                        autoComplete="new-password"
                                        type="password"
                                        label={t('settings.password.label')}
                                        placeholder={t('settings.password.placeHolder')}
                                    />
                                </div>
                            </>)}
                            {(values.integrationType !== null && values.integrationType === 1) && (
                                <div className='col-span-2 p-4 bg-sidebar rounded-md'>
                                    <DarkInput 
                                        name="opcServer"
                                        label={t('settings.opcAddress.label')}
                                        placeholder={t('settings.opcAddress.placeHolder')}
                                    />
                                </div>
                            )}
                            <div className='col-span-1 p-4 bg-sidebar rounded-md'>
                                <NumberInput 
                                    name="keepLogDay"
                                    label={t('settings.loggingPeriod')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-4 flex justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
                       <Button 
                            type="submit"
                            color="green"
                            loading={isSubmitting}
                            disabled={!isValid || !dirty}
                        >
                            {!isSubmitting && <FiSave className='mr-2 w-5 h-5' />}
                            {t('global.buttons.update')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}