import { useSelector } from "react-redux";
import store from "store";
import {
  setApps,
  startLoading,
  stopLoading,
  deleteApp,
  setError,
} from "store/AppSlice";
import AppService from "services/AppService";
import Table from "misc/table";
import { TbApps, TbEdit, TbTrash } from "react-icons/tb";
import { Button } from "misc/form";
import { useNavigate } from "react-router-dom";
import { selectApp } from "store/AppSlice";
import { useComponentDidMount } from "misc/componentManager";
import { MdOutlineOnlinePrediction, MdOutlineOfflinePin } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useConfirm } from "misc/confirm";

const AppList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { apps, isLoading } = useSelector((state) => state.apps);

  const editApp = (app) => {
    store.dispatch(selectApp(app));
    navigate("edit");
  };
  const getApps = async () => {
    store.dispatch(startLoading());
    await AppService.getList()
      .then((res) => {
        const data = res.data.data;
        store.dispatch(setApps(data));
        store.dispatch(setError(false));
      })
      .catch((error) => {
        store.dispatch(setError(true));
        error.handleGlobally && error.handleGlobally();
      })
      .finally(() => {
        store.dispatch(stopLoading());
      });
  };

  // Delete selected app
  const confirmDelete = (app) => {
    confirm(
      t("apps.deleteApp.question", { appname: app.application }),
      async () => {
        store.dispatch(startLoading());
        await AppService.deleteApp(app.id)
          .then(() => {
            store.dispatch(deleteApp(app.id));
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );
  };

  useComponentDidMount(() => {
    getApps();
  });

  const heads = [
    {
      title: "#",
      style: "w-8",
    },
    {
      title: t("apps.table.appName"),
      style: "",
      sortable: true,
    },
    {
      title: t("apps.table.info"),
      style: "",
    },
    {
      title: t("apps.table.ipAddress"),
      style: "",
      sortable: true,
    },
    {
      title: t("apps.table.port"),
      style: "w-28",
    },
    {
      title: t("apps.table.status"),
      style: "w-28 text-center",
    },
    {
      title: "Devices",
      style: "w-28 text-center",
    },
    {
      title: "States",
      style: "w-28 text-center",
    },
    {
      title: t("apps.table.action"),
      style: "w-28 text-center items-center",
    },
  ];
  return (
    <div className="grid grid-cols-1">
      <div className="flex justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
        <Button
          type="button"
          color="default"
          onClick={() => navigate("create")}
        >
          <TbApps className="mr-2 w-5 h-5 transition-all" />{" "}
          {t("global.buttons.add")}
        </Button>
      </div>
      <div className="grid gap-x-4 grid-cols-2">
        <div className="col-span-2 transition-all">
          <Table
            isLoading={isLoading}
            head={heads}
            body={apps.map((app) => [
              app.id,
              app.application ?? "",
              app.info ?? "",
              app.ipAddress ?? "",
              app.port,
              <div className="flex items-center justify-center">
                {app.active ? (
                  <MdOutlineOnlinePrediction
                    size={24}
                    className="text-green-600"
                  />
                ) : (
                  <MdOutlineOfflinePin size={24} className="text-red-600" />
                )}
              </div>,
              <div className="flex items-center justify-center">
                {app.deviceCount}
              </div>,
              <div className="flex items-center justify-center">
                {app.stateCount}
              </div>,
              <div className="grid grid-cols-2 gap-x-4">
                <Button
                  type="button"
                  color="info"
                  size="icon"
                  onClick={() => editApp(app)}
                >
                  <TbEdit size={18} />
                </Button>
                <Button
                  type="button"
                  color="error"
                  size="icon"
                  onClick={() => confirmDelete(app)}
                  disabled={0 < app.stateCount || 0 < app.deviceCount}
                >
                  <TbTrash size={18} />
                </Button>
              </div>,
            ])}
            actionStyle={""}
            actionMenu={null}
            searchable={true}
            searchPlaceHolder={t("apps.search.placeHolder")}
          />
        </div>
      </div>
    </div>
  );
};

export default AppList;
