import ApiService from "./ApiService";

class UserService {
    getList = async () => await ApiService.get('users', {})
    addUser = async (values) => await ApiService.post('users', {
        username: values.username,
        password: values.password,
        confirmPassword: values.confirmPassword,
        info: values.info,
        isAdmin: values.isAdmin
    })
    changePassword = async (id, password, confirmPassword) => {
        return await ApiService.put('users', {id, password, confirmPassword});
    }
    deleteUser = async (id) => await ApiService.delete(`users?id=${id}`, {})
}

export const service = new UserService();
export default service;