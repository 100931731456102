import {
  startLoading,
  stopLoading,
  updateApp,
  unselectApp,
} from "store/AppSlice";
import store from "store";
import AppService from "services/AppService";
import { Button, DarkInput, NumberInput, Radio } from "misc/form";
import { Formik, Form } from "formik";
import { HiCog } from "react-icons/hi";
import { useDynamicInput } from "misc/dynamicInputs";
import { TbAppsOff, TbApps, TbTrash } from "react-icons/tb";
import { RxInput } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  useComponentWillUnmount,
  useComponentDidMount,
} from "misc/componentManager";
import { useTranslation } from "react-i18next";
import { FiSave } from "react-icons/fi";
import CommandFormatter from "./CommandFormatter";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { useNotification } from "misc/notification";

const AppEditForm = () => {
  const { t } = useTranslation();
  const { success, error } = useNotification();
  const { inputs, addField, setField, removeField, resetField, changeField } =
    useDynamicInput();
  const formikRef = useRef();
  const navigate = useNavigate();
  const { selectedApp } = useSelector((state) => state.apps);
  let [updateFormDynamicInputs, setUpdateFormDynamicInputs] = useState([]);
  let [defaultCaption, setDefaultCaption] = useState(null);
  let [defaultSource, setDefaultSource] = useState(null);
  let [defaultDescription, setDefaultDescription] = useState(null);
  useEffect(() => {
    setUpdateFormDynamicInputs(inputs);
    const newConfig = {};
    inputs.map((element) => (newConfig[element.key] = element.value));
    formikRef.current.setFieldValue("config", JSON.stringify(newConfig));
  }, [inputs]);
  useEffect(() => {
    if (selectedApp === null) {
      error("Önce uygulama seçmeniz gerekiyor. ");
      navigate("/apps");
    }
  }, [selectedApp, navigate, error]);

  const cancelEdit = () => {
    store.dispatch(unselectApp());
    resetField();
    navigate("/apps");
  };

  const initialValues = () => ({
    id: selectedApp.id ?? null,
    application: selectedApp.application ?? "",
    ipAddress: selectedApp.ipAddress ?? "",
    active: selectedApp.active ?? false,
    port: selectedApp.port ?? 0,
    info: selectedApp.info ?? "",
    config: null,
  });
  useComponentDidMount(() => {
    if (selectedApp.jsonConfig != null) {
      let configs = Object.entries(selectedApp.jsonConfig);
      if (configs && 0 < configs.length) {
        configs.forEach((cfg, index) => {
          const input = inputs.find((f) => f.key === cfg[0]);
          if (input === undefined) {
            setField(cfg[0], cfg[1]);
            if (cfg[0] === "caption") {
              setDefaultCaption(cfg[1]);
            }
            if (cfg[0] === "source") {
              setDefaultSource(cfg[1]);
            }
            if (cfg[0] === "description") {
              setDefaultDescription(cfg[1]);
            }
          }
        });
      }
    }
  });

  const setCaption = (value) => {
    let findIndex = inputs.findIndex((f) => f.key === "caption");
    if (-1 < findIndex) {
      removeField(findIndex);
    }
    setField("caption", value);
  };
  const setSource = (value) => {
    let findIndex = inputs.findIndex((f) => f.key === "source");
    if (-1 < findIndex) {
      removeField(findIndex);
    }
    setField("source", value);
  };
  const setDescription = (value) => {
    let findIndex = inputs.findIndex((f) => f.key === "description");
    if (-1 < findIndex) {
      removeField(findIndex);
    }
    setField("description", value);
  };

  useComponentWillUnmount(() => {
    resetField();
  });

  return (
    <div className="gird grid-cols-3">
      <div className="flex justify-end items-right text-lg pb-2 border-b-sidebar text-link font-medium col-span-3">
        <Button type="button" color="error" onClick={cancelEdit}>
          <TbAppsOff className="mr-2 w-5 h-5 transition-all" />{" "}
          {t("global.buttons.cancel")}
        </Button>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues()}
        onSubmit={async (values) => {
          store.dispatch(startLoading());
          await AppService.updateApp(values)
            .then((res) => {
              const data = res.data.data;
              store.dispatch(updateApp(data));
              success(t("global.messages.updated"));
              navigate("/apps");
            })
            .catch((error) => {
              error.handleGlobally && error.handleGlobally();
            })
            .finally(() => {
              store.dispatch(stopLoading());
            });
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form className="grid grid-cols-3 col-span-3 gap-x-2">
            <div className="col-span-2 bg-sidebar rounded-md  transition-all">
              <div className="flex bg-black rounded-t-md px-4 h-12">
                <span className="flex-auto text-link font-medium text-xs flex items-center">
                  <TbApps className="mr-2 w-5 h-5 transition-all" size={24} />
                  {t("apps.forms.title")}
                </span>
              </div>
              <div className="px-4 py-4 grid gap-y-2">
                <DarkInput
                  name="application"
                  label={t("apps.forms.addApp.application.label")}
                  placeholder={t("apps.forms.addApp.application.placeHolder")}
                />
                <DarkInput
                  name="ipAddress"
                  label={t("apps.forms.addApp.ipAddress.label")}
                  placeholder={t("apps.forms.addApp.ipAddress.placeHolder")}
                />
                <NumberInput
                  name="port"
                  label={t("apps.forms.addApp.portNumber.label")}
                  placeholder={t("apps.forms.addApp.portNumber.placeHolder")}
                />
                <Radio
                  label={t("apps.forms.addApp.options.label")}
                  name="active"
                  options={[
                    {
                      key: true,
                      value: t("apps.forms.addApp.options.active"),
                    },
                    {
                      key: false,
                      value: t("apps.forms.addApp.options.passive"),
                    },
                  ]}
                />
                <DarkInput
                  name="info"
                  label={t("apps.forms.addApp.info.label")}
                  placeholder={t("apps.forms.addApp.info.placeHolder")}
                />
              </div>
            </div>
            <div className="col-span-1 bg-sidebar rounded-md  transition-all">
              <div className="flex bg-black rounded-t-md pl-4 mb-4">
                <span className="flex-auto text-link font-medium text-xs flex items-center">
                  <HiCog className="mr-2 w-5 h-5 transition-all" size={24} />
                  {t("apps.forms.extras.title")}
                </span>
                <div className="flex-none">
                  <Button type="button" color="link" onClick={addField}>
                    <RxInput className="mr-2 w-5 h-5 transition-all" />
                    {t("apps.forms.extras.buttons.add")}
                  </Button>
                </div>
              </div>
              {0 < updateFormDynamicInputs.length ? (
                updateFormDynamicInputs.map((element, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-9 gap-x-2 transition-all px-4"
                  >
                    <div className="col-span-4">
                      <DarkInput
                        name="key"
                        label={t("misc.dynamicInput.key.label")}
                        placeholder={t("misc.dynamicInput.key.placeHolder")}
                        value={element.key || ""}
                        onChange={(e) => changeField(index, e)}
                      />
                    </div>
                    <div className="col-span-4">
                      <DarkInput
                        name="value"
                        label={t("misc.dynamicInput.value.label")}
                        placeholder={t("misc.dynamicInput.value.placeHolder")}
                        value={element.value || ""}
                        onChange={(e) => changeField(index, e)}
                        disabled={
                          element.key === "description" ||
                          element.key === "source" ||
                          element.key === "caption"
                        }
                      />
                    </div>
                    <div className="col-span-1 mt-5 flex items-center">
                      <Button
                        type="button"
                        className="flex items-center justify-center w-10 h-9 rounded text-center bg-backdrop text-sm text-red-600 disabled:bg-opacity-50"
                        onClick={() => removeField(index)}
                      >
                        <TbTrash size={18} />
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full py-6 text-center font-xs text-yellow-600">
                  <span>{t("apps.forms.extras.info")}</span>
                </div>
              )}
            </div>
            <div className="col-span-2 mt-2">
              <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-backdrop p-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-300",
                        "focus:outline-none",
                        selected
                          ? "bg-sidebar shadow"
                          : "text-gray-100 hover:bg-sidebar/[0.12] hover:text-white"
                      )
                    }
                  >
                    {t("commands.caption")}
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-300",
                        "focus:outline-none",
                        selected
                          ? "bg-sidebar shadow"
                          : "text-gray-100 hover:bg-sidebar/[0.12] hover:text-white"
                      )
                    }
                  >
                    {t("commands.source")}
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-300",
                        "focus:outline-none",
                        selected
                          ? "bg-sidebar shadow"
                          : "text-gray-100 hover:bg-sidebar/[0.12] hover:text-white"
                      )
                    }
                  >
                    {t("commands.description")}
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel
                    unmount={false}
                    className={classNames(
                      "rounded-xl bg-sidebar p-3",
                      "ring-sidebar ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-2"
                    )}
                  >
                    <CommandFormatter
                      sendFormat={setCaption}
                      selectedDatas={defaultCaption}
                    />
                  </Tab.Panel>
                  <Tab.Panel
                    unmount={false}
                    className={classNames(
                      "rounded-xl bg-sidebar p-3",
                      "ring-sidebar ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-2"
                    )}
                  >
                    <CommandFormatter
                      sendFormat={setSource}
                      selectedDatas={defaultSource}
                    />
                  </Tab.Panel>
                  <Tab.Panel
                    unmount={false}
                    className={classNames(
                      "rounded-xl bg-sidebar p-3",
                      "ring-sidebar ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-2"
                    )}
                  >
                    <CommandFormatter
                      sendFormat={setDescription}
                      selectedDatas={defaultDescription}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
            <div className="flex justify-end items-right text-lg pt-2 border-b-sidebar text-link font-medium col-span-3">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                color="success"
              >
                {!isSubmitting && <FiSave className="mr-2 w-5 h-5" />}
                {t("global.buttons.update")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AppEditForm;
