import * as Yup from 'yup';
import i18n from "i18n";

export const UserPasswordValidation = Yup.object().shape({
    password: Yup
                .string()
                .required(i18n.t('user.changePassword.from.password.validations.required')),
    confirmPassword: Yup
                .string()
                .required(i18n.t('user.changePassword.from.confirmPassword.validations.required')),
});

export default UserPasswordValidation;