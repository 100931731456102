import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: {
    applicationCount: 0,
    stateCount: 0,
  },
};

export const statisticSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStats: (state, action) => {
      state.data = action.payload;
    },
    resetStats: (state) => {
      state.data = null;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setStats, resetStats, startLoading, stopLoading } =
  statisticSlice.actions;
export default statisticSlice.reducer;
