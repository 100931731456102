import { Button, TagButton } from "misc/form";
import { BiAddToQueue } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table from "misc/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setAccessStates,
  resetAccessStates,
  selectId,
  unselectId,
  deleteAccessState,
  startLoading,
  stopLoading,
  stopReload,
  startReload,
  selectAccessState,
} from "store/StateSlice";
import {
  useComponentDidMount,
  useComponentWillUnmount,
} from "misc/componentManager";
import store from "store";
import StateService from "services/StateService";
import { createModal } from "misc/modals/ModalHook";
import {
  TbApps,
  TbSquareRounded,
  TbSquareRoundedCheckFilled,
  TbSquareRoundedXFilled,
  TbTrash,
  TbEdit,
} from "react-icons/tb";
import { useConfirm } from "misc/confirm";

const StateList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirm();

  const { accessStates, selectedIds, isLoading, reload } = useSelector(
    (state) => state.states
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const assingmentDialog = (ids) => createModal("state-assignment", ids);

  useEffect(() => {
    setPageNumber(accessStates.pageNumber);
    setPageSize(accessStates.pageSize);
    setSortColumn(accessStates.order.column);
    setSortDirection(accessStates.order.sort);
  }, [accessStates]);

  useEffect(() => {
    if (reload) {
      const idLength = selectedIds.length;
      if (0 < idLength) {
        selectedIds.forEach((id) => store.dispatch(unselectId(id)));
      }
    }
  }, [reload, selectedIds]);

  useEffect(() => {
    const loadData = async () => {
      store.dispatch(startLoading());
      await StateService.getStates({
        pageNumber,
        pageSize,
        column: sortColumn,
        sort: sortDirection,
      })
        .then((res) => {
          const result = res.data;
          store.dispatch(setAccessStates(result));
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        })
        .finally(() => {
          store.dispatch(stopLoading());
          store.dispatch(stopReload());
        });
    };
    loadData();
  }, [pageNumber, pageSize, sortColumn, sortDirection, reload]);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleSorting = (sortType) => {
    if (sortType === null) {
      setSortColumn("id");
      setSortDirection("desc");
    } else {
      setSortColumn(sortType.column);
      setSortDirection(sortType.orderBy);
    }
  };

  const handleSelect = (id) => {
    const selected = hasSelected(id);
    if (selected) {
      store.dispatch(unselectId(id));
    } else {
      store.dispatch(selectId(id));
    }
  };

  const handleSelectToggle = () => {
    const statesLength = accessStates.data.length;
    const idLength = selectedIds.length;

    if (0 < statesLength && statesLength === idLength) {
      accessStates.data.forEach((state) =>
        store.dispatch(unselectId(state.id))
      );
    } else if (0 < statesLength && 0 < idLength && idLength < statesLength) {
      accessStates.data.forEach((state) => {
        const selected = hasSelected(state.id);
        if (!selected) {
          store.dispatch(selectId(state.id));
        }
      });
    } else if (0 < statesLength && idLength === 0) {
      accessStates.data.forEach((state) => {
        const selected = hasSelected(state.id);
        if (!selected) {
          store.dispatch(selectId(state.id));
        }
      });
    }
  };

  // Delete selected app
  const confirmDelete = (state) =>
    confirm(
      t("states.deleteState.question", { statename: state.value }),
      async () => {
        store.dispatch(startLoading());
        await StateService.deleteForAccess(state.id)
          .then(() => {
            store.dispatch(deleteAccessState(state.id));
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );

  const confirmUnassign = (state, app) =>
    confirm(
      t("states.unassignState.question", {
        statename: state.value,
        appname: app.application,
      }),
      async () => {
        store.dispatch(startLoading());
        await StateService.unassignAccessState({
          applicationId: app.id,
          stateId: state.id,
        })
          .then(() => {
            store.dispatch(startReload());
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      },
      () => {}
    );

  const hasSelected = (id) => {
    const i = selectedIds.findIndex((e) => e === id);

    return -1 < i ? true : false;
  };

  useComponentDidMount(() => {
    handlePageChange(1);
  });
  useComponentWillUnmount(() => {
    store.dispatch(resetAccessStates());
  });

  const parseToggleIcon = () => {
    const statesLength = accessStates.data.length;
    const idLength = selectedIds.length;
    if (0 < statesLength && statesLength === idLength) {
      return <TbSquareRoundedCheckFilled className="text-primary" size={18} />;
    } else if (0 < statesLength && 0 < idLength && idLength < statesLength) {
      return <TbSquareRoundedXFilled className="text-primary" size={18} />;
    } else if (0 < statesLength && idLength === 0) {
      return <TbSquareRounded size={18} className="text-link" />;
    }
  };

  const selectForEdit = (state) => {
    store.dispatch(selectAccessState(state));
    navigate("/states/edit");
  };
  const heads = [
    {
      title: (
        <div className="flex justify-center text-center">
          <Button size="icon" onClick={handleSelectToggle}>
            {parseToggleIcon()}
          </Button>
        </div>
      ),
      style: "w-8",
      column: "id",
    },
    {
      title: "#",
      style: "w-8",
      column: "id",
    },
    {
      title: t("states.tables.stateList.value"),
      style: "",
      sortable: true,
      column: "value",
    },
    {
      title: t("states.tables.stateList.description"),
      style: "",
      sortable: true,
      column: "description",
    },
    {
      title: t("states.tables.stateList.info"),
      style: "",
      sortable: true,
      column: "info",
    },
    {
      title: t("states.tables.stateList.application"),
      style: "",
      sortable: false,
      column: "id",
    },
    {
      title: t("states.tables.stateList.action"),
      style: "w-28 text-center items-center",
    },
  ];

  return (
    <div className="grid grid-cols-1">
      <div className="flex gap gap-x-2 justify-end items-right text-lg pb-4 border-b-sidebar text-link font-medium">
        {0 < selectedIds.length && (
          <Button
            type="button"
            color="error"
            onClick={() => assingmentDialog(selectedIds)}
          >
            <TbApps className="mr-2 w-5 h-5 transition-all" />
            {t("states.buttons.assignStatesToApp", {
              count: selectedIds.length,
            })}
          </Button>
        )}
        <Button type="button" color="green" onClick={() => navigate("create")}>
          <BiAddToQueue className="mr-2 w-5 h-5 transition-all" />{" "}
          {t("global.buttons.add")}
        </Button>
      </div>
      <div className="col-span-2 transition-all">
        <Table
          isLoading={isLoading}
          serverSide={true}
          head={heads}
          searchable={false}
          searchPlaceHolder={"Cihazlarda ara"}
          body={accessStates.data.map((state, index) => [
            <div className="flex justify-center text-center">
              <Button size="icon" onClick={() => handleSelect(state.id)}>
                {hasSelected(state.id) ? (
                  <TbSquareRoundedCheckFilled
                    className="text-primary"
                    size={18}
                  />
                ) : (
                  <TbSquareRounded size={18} className="text-link" />
                )}
              </Button>
            </div>,
            (accessStates.pageNumber - 1) * accessStates.pageSize + 1 + index,
            state.value,
            state.description,
            state.info,
            <div className="flex gap-x-2">
              {state.applications.map((app, index) => {
                return (
                  <TagButton
                    key={index}
                    onClick={() => confirmUnassign(state, app)}
                    color="danger"
                    Icon={TbApps}
                    text={app.application}
                  />
                );
              })}
            </div>,
            <div className="flex justify-center text-center gap-x-2">
              <Button
                type="button"
                color="warning"
                size="icon"
                onClick={() => selectForEdit(state)}
              >
                <TbEdit size={18} />
                <div className="duetron-tooltip group-hover/button:block">
                  {t("global.buttons.edit")}
                </div>
              </Button>
              <Button
                type="button"
                color="error"
                size="icon"
                onClick={() => confirmDelete(state)}
                disabled={0 < state.applications.length}
                style={{
                  pointerEvents: "fill",
                }}
              >
                <TbTrash size={18} />
                <div className="duetron-tooltip group-hover/button:block">
                  {0 < state.applications.length
                    ? t("states.buttons.disabledDelete")
                    : t("global.buttons.delete")}
                </div>
              </Button>
            </div>,
          ])}
          pagination={{
            firstPage: accessStates.firstPageNumber,
            lastPage: accessStates.lastPageNumber,
            nextPage: accessStates.nextPageNumber,
            pageNumber: accessStates.pageNumber,
            pageSize: accessStates.pageSize,
            previousPage: accessStates.previousPageNumber,
            totalPages: accessStates.totalPages,
            totalRecords: accessStates.totalRecords,
          }}
          changePageHandler={(uri) => handlePageChange(uri)}
          changeSortingHandler={(sortType) => handleSorting(sortType)}
        />
      </div>
    </div>
  );
};

export default StateList;
