import ApiService from "./ApiService";

class AppService {
    getList = async () => await ApiService.get('apps', {});
    addApp = async (values) => await ApiService.post('apps', values);
    updateApp = async (values) => await ApiService.put('apps', values);
    deleteApp = async (id) => await ApiService.delete(`apps?id=${id}`, {})
}

export const service = new AppService();
export default service;