import ApiService from "./ApiService";

class LogService {
  getActivities = async ({ params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`logs/activities?${queryParams}`, {});
  };
  getErrors = async ({ params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`logs/errors?${queryParams}`, {});
  };

  getOperations = async ({ params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`logs/operations?${queryParams}`, {});
  };

  getStates = async ({ params }) => {
    var queryParams = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return await ApiService.get(`logs/states?${queryParams}`, {});
  };
}

export const service = new LogService();
export default service;
