import classNames from "classnames";
import * as CommandConstants from "constants/CommandConstants"
import { Button } from "misc/form";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Select from "react-tailwindcss-select";
const CommandFormatter = ({sendFormat, selectedDatas = null}) => {
    const {t} = useTranslation();

    const [data, setData] = useState(null);
    const [seperator, setSepeator] = useState(null);
    const [preview, setPreview] = useState("");
    const [command, setCommand] = useState("");
    const [ options ] = useState([
        {
            value: "f1",
            label: t('commands.deviceAddress'),
            disabled: false
        },
        {
            value: "f2",
            label: t('commands.deviceName'),
            disabled: false
        },
        {
            value: "f3",
            label: t('commands.stateValue'),
            disabled: false
        },
        {
            value: "f4",
            label: t('commands.stateDescription'),
            disabled: false
        },
        {
            value: "f5",
            label: t('commands.stateInfo'),
            disabled: false
        }
    ])
    useEffect(() => {
        let selectedDatas = []
        let selectedCommandSets = []
        if(data !== null) {
            data.forEach((d) => {
                selectedDatas.push(d.label)
                selectedCommandSets.push(d.value)
            })
        }
        if(seperator !== null) {
            setPreview(selectedDatas.join(seperator.label))
            setCommand(selectedCommandSets.join(seperator.value))
        }
    }, [data,seperator])

    useEffect(() => {
        if(selectedDatas) {
            const selectedArray = selectedDatas.match(/.{2}/g);
            const cmds = selectedArray.filter(v => v.startsWith('f'));
            const selectedCmds = [];
            cmds.forEach((d, i) => {
                const obj = options.find(f => f.value === d);
                selectedCmds.push(obj);
                handleChange(selectedCmds)
            })
            const spr = selectedArray.filter(v => v.startsWith('s'));
            spr.forEach((d, i) => {
                const obj = CommandConstants.CommandSeperators.find(f => f.value === d);
                handleSeperator(obj)
            })
        }
    }, [selectedDatas, options])
    const handleCommand = () => sendFormat(command)

    const handleChange = value => {
        setData(value);
    };
    const handleSeperator = value => {
        setSepeator(value)
    }
    return (<div className="grid grid-cols-3 gap-4 bg-sidebar rounded-md p-4">
        <div className="col-span-2">
            <div className="grid grid-cols-1 gap-y-4">
                <div className="duetron-form-label">
                    {t('commands.dataType')}
                </div>
                <Select 
                    placeholder={t('misc.select.placeHolder')}
                    value={data}
                    options={options} 
                    onChange={handleChange}
                    isMultiple={true}
                    classNames={{
                        menuButton: ({ isDisabled }) => classNames({
                            'flex text-sm text-link border border-footer rounded shadow-sm transition-all duration-300 focus:outline-none': true,
                            'bg-gray-200': isDisabled,
                            'bg-backdrop text-link hover:border-primary': !isDisabled
                        }),
                        menu: "absolute z-10 w-full bg-backdrop shadow-lg border border-sidebar rounded py-1 mt-1.5 text-sm text-link",
                        listItem: ({ isSelected }) => classNames({
                            'block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded': true,
                            'text-link bg-sidebar': isSelected,
                            'text-link hover:bg-sidebar hover:text-link': !isSelected
                        }),
                        tagItem: ({isSelected, item}) => (
                            `bg-sidebar text-primary flex rounded-md flex items-center`
                        ),
                        tagItemText: 'text-link ml-2',
                        tagItemIconContainer: 'ml-2 hover:bg-primary hover:text-backdrop p-2 rounded-full cursor-pointer'
                    }} 
                />
                {preview && (
                    <div>
                        <div className="duetron-form-label">{t('commands.preview')}</div>
                        <div className="bg-backdrop rounded-md p-2">
                            {preview}
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="col-span-1">
            <div className="grid grid-cols-1 gap-y-4">
            <div className="duetron-form-label">
                    {t('commands.seperator')}
            </div>
            <Select 
                    placeholder={t('misc.select.placeHolder')}
                    value={seperator}
                    options={CommandConstants.CommandSeperators} 
                    onChange={handleSeperator}
                    classNames={{
                        menuButton: ({ isDisabled }) => classNames({
                            'flex text-sm text-link border border-footer rounded shadow-sm transition-all duration-300 focus:outline-none py-1': true,
                            'bg-gray-200': isDisabled,
                            'bg-backdrop text-link hover:border-primary': !isDisabled
                        }),
                        menu: "absolute z-10 w-full bg-backdrop shadow-lg border border-sidebar rounded py-1 mt-1.5 text-sm text-link",
                        listItem: ({ isSelected }) => classNames({
                            'block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded': true,
                            'text-link bg-sidebar': isSelected,
                            'text-link hover:bg-sidebar hover:text-link': !isSelected
                        }),
                        tagItem: ({isSelected, item}) => (
                            `bg-sidebar text-primary flex p-1 rounded-md flex items-center`
                        ),
                        tagItemText: 'text-link ml-2',
                        tagItemIconContainer: 'ml-2 hover:bg-primary hover:text-backdrop p-2 rounded-full cursor-pointer'
                    }} 
                />
                {command && (
                    <div>
                        <Button type="button" color="default" onClick={handleCommand}>{t('commands.useCommand')}</Button>
                    </div>
                )}   
            </div>   
        </div>
    </div>)
}

export default CommandFormatter;